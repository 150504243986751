import {EscortIds, EscortSlug} from './types';

export const ESCORT_CATEGORY_BY_ID = {
  [EscortSlug.Female]: EscortIds.Female,
  [EscortSlug.Shemale]: EscortIds.Shemale,
  [EscortSlug.BodyRybs]: EscortIds.BodyRybs,
};

export const VIDEO_PROCESSING_STATUS = {
  Uploaded: 1,
  Processing: 2,
  ProcessedSuccessfully: 3,
  Error: 4,
};
