import {FC, useMemo} from 'react';
import {useStore, useUnit} from 'effector-react';
import Skeleton from 'react-loading-skeleton';

import {$placesTypes, loadPlacesTypesFx} from '~/store/profiles';
import {$city, fetchCityByUrlFx} from '~/store/cities';
import ListWithCounters, {ListItem} from '~/components/common/ListWithCounters';

const PlacesCounters: FC = () => {
  const placesTypes = useStore($placesTypes);
  const [loadingPlaces, loadingCity] = useUnit([
    loadPlacesTypesFx.pending,
    fetchCityByUrlFx.pending,
  ]);
  const city = useStore($city);

  const list: ListItem[] = useMemo(
    () =>
      placesTypes.map(({id, name, count, url}) => ({
        id,
        count,
        text: name,
        url: `${city?.url}/${url}`,
      })),
    [placesTypes, city]
  );

  if (loadingPlaces || loadingCity) {
    return <Skeleton height={30} count={3} className="m-y-15" />;
  }

  if (!placesTypes.length) {
    return null;
  }

  return <ListWithCounters list={list} title={`Adult Places in ${city?.name}`} />;
};

export default PlacesCounters;
