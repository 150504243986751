import {createStore, createEvent} from 'effector';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const cookieName = 'googtrans';
const defaultLanguage = 'en';
let initialLanguage = defaultLanguage;

const initialLanguageStr = cookies.get(cookieName);
if (initialLanguageStr !== undefined) {
  initialLanguage = initialLanguageStr.split('/').pop();
}

export const setTranslationLanguage = createEvent<string>('Set translation language');
export const $translationLanguage = createStore<string>(initialLanguage).on(
  setTranslationLanguage,
  (_, payload) => payload
);

setTranslationLanguage.watch((lang) => {
  const hostElements = window.location.hostname.split('.').reverse();
  const rootDomain =
    hostElements.length > 2 ? `${hostElements[1]}.${hostElements[0]}` : window.location.hostname;

  cookies.remove(cookieName, {path: '/'});
  cookies.remove(cookieName, {path: '/', domain: rootDomain});

  if (lang !== defaultLanguage) {
    cookies.set(cookieName, `/${defaultLanguage}/${lang}`, {
      path: '/',
    });
  }
});
