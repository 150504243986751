import {RouteObject} from 'react-router';

import Page404 from '~/pages/commonPages/Page404';
import {CityRoute} from '~/pages/city';
import {PlaceAdd, PlaceBuy, PlaceUpload} from '~/pages/places';
import {EscortRoute} from '~/pages/escorts/Escort';
import {EscortsCategoryRoute} from '~/pages/escorts/EscortsCategory';
import {PlacesCategoryRoute} from '~/pages/places/PlacesCategory';
import {PlaceRoute} from '~/pages/places/Place';
import {PlaceReviewRoute} from '~/pages/places/PlaceReview';
import PrivateRoute from '~/components/system/PrivateRoute';
import {ForumsList, ForumsRoute} from '~/pages/forum/route';
import {fetchCityByUrl} from '~/store/cities';

import LocationPage from './LocationPage';

const Page404Item = {
  path: '*',
  element: <Page404 />,
};

const LocationRoute: RouteObject = {
  path: ':countryCode/:stateName?/:cityName/*',
  loader: ({params: {countryCode, stateName, cityName}}) => {
    if (countryCode && cityName) {
      fetchCityByUrl({countryCode, stateName, cityName});
    }

    return null;
  },
  shouldRevalidate: ({currentParams, nextParams}) => {
    if (
      currentParams.cityName !== nextParams.cityName ||
      currentParams.stateName !== nextParams.stateName
    ) {
      return true;
    }

    return false;
  },
  children: [
    {
      element: <LocationPage key="location" />,
      children: [
        CityRoute,
        ...EscortsCategoryRoute,
        ...PlacesCategoryRoute,
        ForumsList,
        Page404Item,
      ],
    },
    ...EscortRoute,
    ...PlaceRoute,
    ...PlaceReviewRoute,
    {
      path: 'add/:placeId?',
      element: (
        <PrivateRoute>
          <PlaceAdd />
        </PrivateRoute>
      ),
    },
    {
      path: 'buy/:placeId',
      element: <PlaceBuy />,
    },
    {
      path: 'upload/:placeId/:type',
      element: <PlaceUpload />,
    },
    ForumsRoute,
    Page404Item,
  ],
};

export default LocationRoute;
