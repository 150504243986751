import {HTMLAttributeAnchorTarget} from 'react';
import {To} from 'react-router-dom';

import {navigate} from '~/router';
import {isExternalLink} from '~/utils/url';

export interface HistoryState {
  pageScrollY?: number;
}

const pathScrollHistory = new Map<string, number>();

export const saveScrollPositionAndRedirect = (
  to: To,
  target: HTMLAttributeAnchorTarget | undefined
) => {
  const {pathname, search} = window.location;
  let link = '';

  if (typeof to === 'object') {
    if (to.pathname) {
      link = to.pathname;
    }
  } else {
    link = to;
  }

  if (isExternalLink(link)) {
    if (target === '_blank') {
      window.open(link);
      return;
    }

    window.location.href = link;
    return;
  }

  pathScrollHistory.set(pathname + search, window.scrollY);

  navigate(link);
};

export const restoreScrollPosition = () => {
  const {pathname, search} = window.location;
  const path = pathname + search;
  const pageScrollY = pathScrollHistory.get(path);

  if (pageScrollY) {
    pathScrollHistory.delete(path);

    setTimeout(() => {
      window.scrollTo(0, pageScrollY);
    });
  }
};
