import {createStore, createEvent, createEffect, sample, combine} from 'effector';

import {requestEscortsCounters} from '~/services/account';
import {requestMyEscorts} from '~/services/escorts';
import {requestMyPlaces} from '~/services/places';
import {getToken} from '~/services/auth';

import {TMyAdsStore} from './types';
import {refreshAccountData, resetAccount, $account} from './account';

export const clearMyAds = createEvent('Clear myAds');

export const loadMyAdsFx = createEffect<void, TMyAdsStore>().use(async () => {
  const result = await Promise.all([
    requestMyEscorts(),
    requestEscortsCounters(),
    requestMyPlaces(100, 0),
  ]);

  return {
    escorts: result[0],
    counters: result[1],
    places: result[2],
    error: null,
  };
});

export const $myAds = createStore<TMyAdsStore>({
  escorts: null,
  counters: null,
  places: null,
  error: null,
})
  .on(loadMyAdsFx.doneData, (_, payload) => payload)
  .on(loadMyAdsFx.fail, (state, {error}) => {
    return {
      ...state,
      error,
    };
  })
  .reset([clearMyAds, resetAccount]);

sample({
  clock: refreshAccountData,
  filter: () => !!getToken(),
  target: loadMyAdsFx,
});

export const $loading = loadMyAdsFx.pending;

export const $canPostAds = combine($myAds, $account, (myAds, account) => {
  if (!account || !myAds.escorts) {
    return true;
  }

  return myAds.escorts.total < account.maxEscortsNumber;
});
