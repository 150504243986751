import env from '~/config/appEnv';
import {$account} from '~/store/account';

const SentryEngine = window.Sentry;

if (SentryEngine !== undefined) {
  SentryEngine.onLoad(() => {
    if (env.REACT_APP_SENTRY_ENABLED === '1') {
      const account = $account.getState();

      SentryEngine.init({
        environment: env.REACT_APP_SENTRY_ENV,
        dsn: env.REACT_APP_SENTRY_DSN,
        release: __BUILD_INFO__.version,
        ignoreErrors: [],
        tracesSampleRate: 1.0,
        autoSessionTracking: true,
        initialScope: {
          tags: {
            commit: __BUILD_INFO__.commit || 'dev',
            user: account
              ? {
                  id: account.id.toString() || null,
                  username: account.username || null,
                  email: account.email || null,
                }
              : null,
          },
        },
      });
    } else if (process.env.NODE_ENV !== 'test') {
      // eslint-disable-next-line no-console
      console.log('Sentry DISABLED');
    }
  });
}

export const captureException = (...params: any) => {
  SentryEngine?.captureException(...params);
};

export const captureMessage = (...params: any) => {
  SentryEngine?.captureException(...params);
};

export default SentryEngine;
