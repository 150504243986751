import {FC, useMemo} from 'react';
import {useStore} from 'effector-react';

import {$forums} from '~/store/forums';
import {$city} from '~/store/cities';
import ListWithCounters, {ListItem} from '~/components/common/ListWithCounters';

const Forums: FC = () => {
  const forums = useStore($forums);
  const city = useStore($city);

  const list: ListItem[] = useMemo(
    () =>
      forums.map((forum) => ({
        id: forum.id,
        url: `${city?.url}/sex-forum/${forum.url}`,
        text: forum.name,
        count: forum.countTopics,
      })),
    [forums, city]
  );

  if (!forums.length || !city) {
    return null;
  }

  return <ListWithCounters title="Sex forums" list={list} />;
};

export default Forums;
