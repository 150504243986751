import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import {signInFx, logout} from '~/store/auth';
import {getToken} from '~/services/auth';
import adbuild from '~/services/adbuild';

const useExternalAuth = () => {
  const {search} = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const userId = query.get('acc');
    const secret = query.get('al');

    if (!userId || !secret) {
      return;
    }

    if (getToken()) {
      logout();
    }

    adbuild.clearCookie(true);

    signInFx({
      acc: userId,
      al: secret,
    });
  }, [search]);
};

export default useExternalAuth;
