import qs from 'qs';

export enum Page {
  Home = '/',
  Account = '/account',
  MyAds = '/classifieds/myposts',
  Topups = '/classifieds/topups',
  Adbuild = '/adbuild',
  Adbuild1 = '/adbuild',
  Adbuild2 = '/adbuild/step2',
  Adbuild3 = '/adbuild/step3',
  AdbuildConfirm = '/adbuild/confirm',
  Contact = '/contact_us',
  Terms = '/terms_of_service',
  Privacy = '/privacy_policy',
  SignIn = '/account/signin',
  SignUp = '/account/signup',
  Reset = '/account/reset',
  AccountConfirm = '/account/confirm',
  ChangeEmail = '/account/change-email',
  ChangePassword = '/account/change-password',
  ChangeSettings = '/account/change-settings',
  ChatSettings = '/account/chat-settings',
  ConfirmEmail = '/account/confirm-email',
  ChangeToAgency = '/account/change-agency',
  ChangeUsername = '/account/change-username',
  UnverifiedInfo = '/account/unverified',
  Chat = '/chat',
  Faq = '/faq',
  Recharge = '/payment/recharge',
  Payments = '/account/payments',
  Payment = '/payment/pay',
  TwoFactor = '/account/2fa',
  AllLocations = '/all-locations',
}

export const PageRegExp = {
  AdRenew: /\/classifieds\/myposts\/\d+\/renew/,
  AdChangeLocation: /\/classifieds\/myposts\/\d+\/change_location/,
  AdEditThumbnail: /\/classifieds\/myposts\/\d+\/edit_thumbnail/,
  AdDuplicate: /\/classifieds\/myposts\/\d+\/duplicate/,
  AdEdit: /\/classifieds\/myposts\/\d+\/edit/,
  AdStep3: /\/classifieds\/myposts\/\d+\/step3/,
  AdBuyEcortSponsor: /\/classifieds\/myposts\/\d+\/escortSponsor/,
  AdBuyEcortSide: /\/classifieds\/myposts\/\d+\/escortSide/,
  AdBuyEscortSticky: /\/classifieds\/myposts\/\d+\/escortSticky/,
};

export const withParams = (page: Page, params: Record<string, string | number | boolean>) => {
  return `${page}${qs.stringify(params, {addQueryPrefix: true})}`;
};
