const footCmConst = 30.48;
const inchCmConst = 2.54;
const kgLbsConst = 2.20462262;
const mToKmConvertValue = 0.001;
const mToMlConvertValue = 0.00062137;

/**
 * Type for Height measurement
 * ```
 * {
 *  "foot": "5",
 *  "inch": "0.3"
 * }
 * ```
 */
export type TFootInch = {
  foot: string;
  inch: string;
};

/**
 * Convert centimeters to `{ foot, inch }` object
 * @param cm: number
 * @returns
 * ```
 * {
 *  "foot": "5",
 *  "inch": "0.3"
 * }: TFootInch
 * ```
 */
const cmToFootInch: (cm: number) => TFootInch = (cm) => {
  const cmForInches = cm % footCmConst;
  return {
    foot: ((cm - cmForInches) / footCmConst).toFixed(0),
    inch: (cmForInches / inchCmConst).toFixed(2),
  };
};

/**
 * Convert `{ foot, inch }` to `cm`
 */
const footInchToCm: (foot: number, inch: number) => number = (foot, inch) =>
  Math.round(foot * footCmConst + inch * inchCmConst);

/**
 * Convert kilograms to pounds
 */
const kgToLbs = (kg: number) => (kg * kgLbsConst).toFixed(2);
const lbsToKg = (lbs: number) => Math.round(lbs / kgLbsConst);
const metersToKm = (meters: number) => (meters * mToKmConvertValue).toFixed(0);
const metersToMl = (meters: number) => (meters * mToMlConvertValue).toFixed(0);

const inchToCm = (inch: number) => Math.round(inch * inchCmConst);

/**
 * Returns `true` if distance in meters is more than 1.5x mile
 */
const isMoreThanOneMile = (meters: number) => parseFloat(metersToMl(meters)) > 1;

export {
  cmToFootInch,
  footInchToCm,
  isMoreThanOneMile,
  kgToLbs,
  lbsToKg,
  metersToKm,
  metersToMl,
  inchToCm,
};
