import {clearPincodeSession, setPincode} from '~/services/pincode';
import logger from '~/utils/logger';

let pwaEvent: BeforeInstallPromptEvent | null = null;

const handler = (event: BeforeInstallPromptEvent) => {
  event.preventDefault();

  pwaEvent = event;

  setPincode(null);
  clearPincodeSession();

  logger.debug('PWA prompt event', event);
};

export const initPwaEvent = () => {
  window.addEventListener('beforeinstallprompt', handler as any);
};

export const isPwaInited = () => Boolean(pwaEvent);

export const requestPwaInstall = (successMessage: string) => {
  if (!pwaEvent) {
    logger.debug('PWA module not initialized');
  }

  if (pwaEvent?.prompt && pwaEvent?.userChoice) {
    logger.debug('PWA sidebar userChoice', pwaEvent.userChoice);

    pwaEvent.prompt();

    pwaEvent.userChoice.then((choiceResult: any) => {
      logger.debug('PWA sidebar choiceResult', choiceResult);

      if (choiceResult.outcome === 'accepted') {
        logger.debug(successMessage);
      }
    });
  }
};
