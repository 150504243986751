import qs from 'qs';

export const getOffsetFromQueryString = (search: string) => {
  const qsParams = qs.parse(search, {ignoreQueryPrefix: true});
  const parsedOffset = qsParams.offset ? parseInt(String(qsParams.offset), 10) : 0;

  return parsedOffset;
};

export const getLimitFromStorage = (key: string, limits: number[]) => {
  try {
    const restoredLimit = window.localStorage.getItem(`itemsPerPage-${key}`);
    const parsedLimit = restoredLimit ? parseInt(restoredLimit, 10) : limits[0];

    return parsedLimit;
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
  }

  return 10;
};
