import {lazy} from 'react';

import logger from '~/utils/logger';

// in case of ChunkLoadError we are trying to reload just ONCE with REFRESH_APP=1
export const lazyWithPreload = (factory: () => Promise<any>) => {
  const Component: any = lazy(() =>
    factory().catch((error) => {
      const hasChunkLoadError = window.sessionStorage.getItem('chunkLoadError') === '1';

      if (!hasChunkLoadError && error.name.includes('ChunkLoadError')) {
        logger.error(`Cannot load chunk: ${error.message}`);

        window.sessionStorage.setItem('chunkLoadError', '1');
        window.location.href = '/?REFRESH_APP=1';

        return {default: () => null}; // to conform React.lazy expectation
      }

      throw error;
    })
  );

  Component.preload = factory;

  return Component;
};
