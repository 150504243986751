import axios, {request, requestPost, requestPut} from '~/services/api_init';
import {TLoginResponse, TLoginPayload} from '~/services/types';

import {
  TAccount,
  TAccountSettings,
  TAccountStatistics,
  TAccountEscortCounters,
  TAccountSponsorshipCount,
} from './types';
import * as urls from './constants';

export const requestLogin = (values: TLoginPayload) =>
  axios.post<TLoginResponse>('/api/v1/login', values).then((res) => res.data);

export const requestLogout = () => requestPost<void>('/api/v1/pc/logout');

export const requestAccount = () => request<TAccount>(urls.AccountUrl);

export const requestSettings = () => request<TAccountSettings>(urls.AccountSettingsUrl);

export const requestStatistics = () => request<TAccountStatistics>(urls.MyStatsUrl);

export const requestEscortsCounters = () =>
  request<TAccountEscortCounters>(urls.MyEscortCountersUrl);

export const requestChangeToAgency = () => requestPut<void>(urls.ChangeToAgencyUrl);

const checkedAccounts = new Set();

export const ensureUserHasFlashAccount = (id: number) => {
  if (checkedAccounts.has(id)) {
    return Promise.resolve();
  }

  checkedAccounts.add(id);

  return request<void>(`/api/v1/account/chat/check/${id}`);
};

export const requestSponsorshipCount = (ids: number[]) =>
  request<TAccountSponsorshipCount>(urls.MySponsorshipUrl, {ids});

export const requestMoveToUnverified = () => requestPut<void>(urls.MoveToUnverifiedUrl);

export const isAccountUnverified = (account: TAccount) => {
  return account.type === 'U';
};

export const requestHotPopupShown = () => requestPost<void>('/api/v1/pc/account/hot_popup_shown');
