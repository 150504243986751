import {FC} from 'react';
import {useStore, useUnit} from 'effector-react';
import Skeleton from 'react-loading-skeleton';

import config from '~/config';
import LinkStoreScroll from '~/components/common/LinkStoreScroll';
import Photo from '~/components/common/Photo';
import {$city, fetchCityByUrlFx} from '~/store/cities';

import {$sponsors, loadSponsorsFx} from '../store';

const Sponsors: FC = () => {
  const sponsors = useStore($sponsors);
  const city = useStore($city);
  const [loadingSponsors, loadingCity] = useUnit([
    loadSponsorsFx.pending,
    fetchCityByUrlFx.pending,
  ]);

  if (loadingSponsors || loadingCity) {
    return (
      <div className="p-b-10">
        <Skeleton height={71} count={sponsors.length || 5} className="m-t-5" />
      </div>
    );
  }

  if (!sponsors.length || !city) {
    return null;
  }

  return (
    <div className="city-advertisement-container p-b-10">
      {sponsors.map((sponsor) => {
        const url = `${city.url}/${sponsor.typeSlug}/${sponsor.id}`;

        return (
          <div key={sponsor.id} data-id={sponsor.id} className="city-advertisement bg-white m-t-5">
            <LinkStoreScroll
              to={url}
              className="content-item bg-white d-flex justify-content-between flex-nowrap"
            >
              <div className="left d-flex flex-nowrap">
                <Photo
                  stubSize="small"
                  src={`${config.getImagePath('escortThumbnail')}/${sponsor.thumbnail}`}
                  className="m-r-10"
                  alt={`Sponsor ${sponsor.type}`}
                  loading="lazy"
                />
                <div className="information m-t-5">
                  <div className="weight-600 color-tertiary">{sponsor.type}</div>
                  <span className="d-block">
                    {/* Do not show "Not-set" ASNG-1450 */}
                    {sponsor.ethnicity !== 'Not-set' && sponsor.ethnicity}
                  </span>
                </div>
              </div>

              <div className="right d-flex flex-nowrap m-t-5 m-r-5 color-primary">
                {sponsor.isIncallEnabled && <div className="incall m-r-5">Incall</div>}
                {sponsor.isIncallEnabled && sponsor.isOutcallEnabled && <span>/</span>}
                {sponsor.isOutcallEnabled && <div className="outcall m-l-5">Outcall</div>}
              </div>
            </LinkStoreScroll>
          </div>
        );
      })}
    </div>
  );
};

export default Sponsors;
