import React, {Component} from 'react';
import {Link} from 'react-router-dom';

export default class ButtonBordered extends Component {
  getClasses = () => {
    const classes = ['btn', 'primary-bordered-btn'];

    if (this.props.classes) {
      classes.push(this.props.classes);
    }

    return classes.join(' ');
  };

  render() {
    const id = this.props.id || '';
    const text = this.props.text || 'Submit';
    const disabled = this.props.disabled || false;
    const title = this.props.title || '';
    const data = this.props.data || [];

    return (
      <>
        {this.props.link && (
          <Link
            className={this.getClasses()}
            id={id}
            type="text"
            title={title}
            disabled={disabled}
            onClick={this.props.handleClick}
            to={this.props.link}
            {...data}
          >
            <span>{text}</span>
          </Link>
        )}
        {!this.props.link && (
          <button
            className={this.getClasses()}
            id={id}
            type={this.props.type || 'submit'} // eslint-disable-line react/button-has-type
            title={title}
            disabled={disabled}
            onClick={this.props.handleClick}
            {...data}
          >
            <span>{text}</span>
          </button>
        )}
      </>
    );
  }
}
