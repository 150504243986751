import {FC} from 'react';
import Skeleton from 'react-loading-skeleton';
import clsx from 'clsx';

import {Icons} from '../../icons';
import Icon from '../../icons/Icon';
import LinkStoreScroll from '../LinkStoreScroll';
import {ListWithCountersProps} from './types';
import './style.scss';

const ListWithCounters: FC<ListWithCountersProps> = ({list, title, className, loading}) => (
  <div className={clsx('list__root', className)}>
    <h2 className="list__title">{loading ? <Skeleton height={30} count={1} /> : <>{title}</>}</h2>

    {loading && <Skeleton height={30} count={3} className="m-y-15" />}

    {!loading &&
      list.map((item) => (
        <LinkStoreScroll key={item.id} to={item.url} className="list__item">
          <span className="text">{item.text}</span>
          <div className="list__content">
            <span className="list__counter">{item.count}</span>
            <Icon id={Icons.chevron} />
          </div>
        </LinkStoreScroll>
      ))}
  </div>
);

export default ListWithCounters;
