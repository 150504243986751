import {ListResponse, TGalleryMediaItem} from '~/types/common';

export enum PlaceSlug {
  StripClub = 'strip-club',
  SexShop = 'sex-shop',
  FkkClub = 'fkk-club',
  Cabaret = 'cabaret',
  NightClubFreelanceEscorts = 'night-club-freelance-escorts',
  GayBar = 'gay-bar',
  bordello = 'bordello',
  GayFetish = 'gay-fetish',
  GaySauna = 'gay-sauna',
  TrannyShow = 'tranny-show',
  SexKino = 'sex-kino',
  SwingersClub = 'swingers-club',
  Privat = 'privat',
  Studio = 'studio',
  EroticMassageParlor = 'erotic-massage-parlor',
  FetishClub = 'fetish-club',
  AdultTheatre = 'adult-theatre',
  PrivesBrothels = 'prives-brothels',
  Laufhaus = 'laufhaus',
  Personne = 'personne',
  ErosCenter = 'eros-center',
  RedLightDistrict = 'red-light-district',
  GoGoBar = 'go-go-bar',
  FreelanceBar = 'freelance-bar',
  PeepShow = 'peep-show',
  GayLesbianBar = 'gay-lesbian-bar',
  LiveSexShow = 'live-sex-show',
  SexClub = 'sex-club',
  AdultCinema = 'adult-cinema',
  Brothel = 'brothel',
  StripClubBrothel = 'strip-club-brothel',
  SexResort = 'sex-resort',
  BarOfProstitution = 'bar-of-prostitution',
  HotelsFreelanceEscorts = 'hotels-freelance-escorts',
  GirlieBar = 'girlie-bar',
  Club = 'club',
  KtvBar = 'ktv-bar',
  Spa = 'spa',
  HotelMassage = 'hotel-massage',
  Sauna = 'sauna',
  Casino = 'casino',
  MallsWhoreBars = 'malls-whore-bars',
  WhoreHouse = 'whore-house',
  SalonBoomBoom = 'salon-boom-boom',
  Hotel = 'hotel',
  RestaurantBoomBoom = 'restaurant-boom-boom',
  CoffeeShopBlowjob = 'coffee-shop-blowjob',
  ShortTime = 'short-time',
  Disco = 'disco',
  GayBarOfProstitution = 'gay-bar-of-prostitution',
  GayEroticMassageParlor = 'gay-erotic-massage-parlor',
  GayNightClub = 'gay-night-club',
  GayGoGoBar = 'gay-go-go-bar',
  LadyBoyBar = 'lady-boy-bar',
  LadyBoyGoGoBar = 'lady-boy-go-go-bar',
  BeerBar = 'beer-bar',
  GayKtvBar = 'gay-ktv-bar',
  LadyBoyCabaretWhow = 'lady-boy-cabaret-show',
  LadyBoyMassageParlor = 'lady-boy-massage-parlor',
  CabaretShow = 'cabaret-show',
  BrothelBar = 'brothel-bar',
  ShortTimeHotel = 'short-time-hotel',
  BlowJobBar = 'blow-job-bar',
  HostessBar = 'hostess-bar',
  SoapyMassageParlor = 'soapy-massage-parlor',
  PubAndRestaurant = 'pub-and-restaurant',
  Gay = 'gay',
  GayBathHouse = 'gay-bath-house',
  Bdsm = 'bdsm',
  ToplessPool = 'topless-pool',
  NudistColony = 'nudist-colony',
  LingerieModeling1on1 = 'lingerie-modeling-1on1',
  MaleRevue = 'male-revue',
  MaleStripClub = 'male-strip-club',
}

export type PlaceTypesResponse = ListResponse<PlaceType>;

export type PlacesResponse = ListResponse<TPlace>;

interface AttributeItem {
  id: number;
  attributeId: number;
  name: string;
  options: any | null;
  placeId: number;
  title: string;
  type: string;
  value: string;
}

export interface TPlace {
  address1: string;
  address2: string;
  createdAt: string;
  deletedAt: string | null;
  editable: number;
  email: string | null;
  empId: number;
  empImported: any | null;
  facebook: any | null;
  fake: boolean;
  fax: any | null;
  id: number;
  image: string;
  instagram: any | null;
  isTest: boolean;
  lastChecked: string;
  lastCheckedBy: number;
  lastReviewId: number;
  latitude: string;
  line: any | null;
  locationExactId: number;
  locationId: number;
  longitude: string;
  mapImage: string;
  name: string;
  neighborId: any | null;
  oldId: number;
  overall: number;
  ownerId: any | null;
  phone1: string;
  phone2: any | null;
  recommend: number;
  review: number;
  source: any | null;
  sponsorship: number;
  telegram: any | null;
  thumbnail: string;
  twitter: any | null;
  typeId: number;
  updatedAt: string;
  webSite: any | null;
  wechat: any | null;
  whatsapp: any | null;
  workerComment: any | null;
  zipCode: string;
  medias: TGalleryMediaItem[];
  code: string;
}

export interface PlaceType {
  id: number;
  name: string;
  namePlural: string;
  url: string;
  count: number;
}

export type InformationResponse = ListResponse<AttributeItem>;

export type AttributesResponse = ListResponse<AttributeItem>;

interface Review {
  author: {
    accountId: number;
    avatar: string | null;
    name: string;
    reviewId: number;
  };
  comments: any[];
  id: number;
  placeId: number;
  review: {
    accountId: number;
    comment: string;
    id: number;
    ip: string;
    placeId: number;
    providerId: number;
    reviewDate: string;
    star: number;
  };
}

export type ReviewsResponse = ListResponse<Review>;
