import {PlaceSlug} from '~/services/places';
import {lazyWithPreload} from '~/utils/lazyWithPreload';

const PlacesCategory = lazyWithPreload(
  () => import(/* webpackChunkName: 'Places' */ './PlacesCategory')
);

export default Object.values(PlaceSlug).map((slug) => ({
  path: slug,
  element: <PlacesCategory />,
}));
