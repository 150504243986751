import {lazyWithPreload} from '~/utils/lazyWithPreload';

export const PaymentForm = lazyWithPreload(
  () => import(/* webpackChunkName: 'Payment' */ './PaymentForm')
);

export const PaymentRecharge = lazyWithPreload(
  () => import(/* webpackChunkName: 'Payment' */ './PaymentRecharge')
);

export const PaymentStatus = lazyWithPreload(
  () => import(/* webpackChunkName: 'Payment' */ './PaymentStatus')
);
