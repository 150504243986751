import {createStore, createEvent} from 'effector';

import {MODAL_HOT_CAMPAIGN_ID} from '~/layout/ModalHotCampaign/constants';

import {ModalId} from './types';

export const showModal = createEvent<ModalId>('Show modal');
export const hideModal = createEvent<ModalId>('Hide modal');
export const hideAllModals = createEvent('Hide all modals');

const PERMANENT_MODALS = [MODAL_HOT_CAMPAIGN_ID];

export const $modal = createStore<ModalId[]>([])
  .on(showModal, (state, modalId) => {
    if (!state.includes(modalId)) {
      return [...state, modalId];
    }

    return state;
  })
  .on(hideModal, (state, modalId) => {
    return state.filter((id) => id !== modalId);
  })
  .on(hideAllModals, (state) => {
    return state.filter((id) => PERMANENT_MODALS.includes(id));
  });
