import {FC} from 'react';

import useIsOnline from '~/utils/useIsOnline';
import './styles.scss';

const OfflineBar: FC = () => {
  const isOnline = useIsOnline();

  if (isOnline) {
    return null;
  }

  return (
    <div className="offline-bar">
      <div className="offline-bar__bar">
        You’re offline. Connect to the internet to use chat and view ads
      </div>
    </div>
  );
};

export default OfflineBar;
