import {FC, useEffect, useMemo, useRef, memo} from 'react';
import {Link, useParams} from 'react-router-dom';
import clsx from 'clsx';
import Skeleton from 'react-loading-skeleton';
import {useStore} from 'effector-react';

import {$profileTypes} from '~/store/profiles';
import {$city} from '~/store/cities';

import {NavigationCarouselProps} from './types';
import {getIcon} from './helpers';
import './styles.scss';

const NavigationCarousel: FC<NavigationCarouselProps> = ({className}) => {
  const params = useParams();
  const refContainer = useRef<HTMLDivElement>(null);
  const slides = useStore($profileTypes);
  const city = useStore($city);
  const categoryUrl = params['*'];

  const selectedIndex = useMemo(() => {
    return slides.findIndex((slide) => slide?.url === categoryUrl);
  }, [slides, categoryUrl]);

  useEffect(() => {
    const item = refContainer.current?.querySelector('.nav-carousel__item--active');

    if (item) {
      item.scrollIntoView({block: 'center', inline: 'nearest'});
    }
  }, [selectedIndex]);

  const items = slides.map((slide, index) => {
    if (slide === null) {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <div className="nav-carousel__item nav-carousel__item--loading" key={index}>
          <Skeleton width={35} height={35} circle />
          <Skeleton width={40} height={10} borderRadius={2} count={1} />
          <Skeleton width={40} height={10} borderRadius={2} count={1} />
        </div>
      );
    }

    if (!slide.name) {
      return null;
    }

    const svg = getIcon(slide.url);
    const classes = clsx('nav-carousel__item', {
      'nav-carousel__item--active': selectedIndex === index,
      'nav-carousel__item--first': index === 0,
    });

    if (window.location.href.startsWith(`${city?.url}/${slide.url}`)) {
      return (
        <div className={classes} key={slide.name}>
          {svg ? (
            <div id={slide.url} className="nav-carousel__svg">
              {svg}
            </div>
          ) : (
            <div className={`icon icon_${slide.url}`} />
          )}
          <div className="nav-carousel__title">{slide.name}</div>
        </div>
      );
    }

    if (slide.name === 'M4M Escorts') {
      return (
        <a
          className="nav-carousel__item"
          href={slide.url}
          target="_blank"
          key={slide.name}
          rel="noopener noreferrer"
        >
          <div className="nav-carousel__svg">{getIcon('m4m')}</div>
          <div className="nav-carousel__title">{slide.name}</div>
        </a>
      );
    }

    return (
      <Link className={classes} to={`${city?.url}/${slide.url}`} key={slide.name}>
        {svg ? (
          <div id={slide.url} className="nav-carousel__svg">
            {svg}
          </div>
        ) : (
          <div className={`icon icon_${slide.url}`} />
        )}
        <div className="nav-carousel__title">
          {'namePlural' in slide ? slide.namePlural : slide.name}
        </div>
      </Link>
    );
  });

  return (
    <div ref={refContainer} className={clsx('nav-carousel', className)}>
      {city ? <>{items}</> : null}
    </div>
  );
};

export default memo(NavigationCarousel);
