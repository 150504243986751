export const parseEnvToBoolean = (value?: string): boolean => Boolean(parseInt(value || '', 10));

export const parseNumber = (value?: string, defaultValue = 0): number =>
  parseInt(value || '', 10) || defaultValue;

export const ensureSlash = (path?: string) => {
  if (!path) {
    return path;
  }

  const pathLength = path.length;
  const pathLastChar = path[pathLength - 1];

  if (pathLastChar === '/') {
    return path;
  }

  return `${path}/`;
};

export const chompSlash = (path?: string) => {
  if (!path) {
    return path;
  }

  if (path[path.length - 1] === '/') {
    return path.slice(0, -1);
  }

  return path;
};
