import {useEffect, useLayoutEffect, useRef} from 'react';
import {useStore} from 'effector-react';
import {IIdleTimer} from 'react-idle-timer';

import {
  clearPincodeSession,
  getPincode,
  isPincodeSessionStarted,
  isPincodeSetupModalDisabled,
  setPincode,
} from '~/services/pincode';
import customNavigator from '~/utils/navigator';
import env from '~/config/appEnv';

import IdleTimer from './IdleTimer';
import PinCodeSetup from './PinCodeSetup';
import PinCode from './PinCode';
import {$pincodeStage, setPincodeStage} from './store';

function PinCodeInit() {
  const pincodeStage = useStore($pincodeStage);
  const idleTimerRef = useRef<IIdleTimer | null>(null);
  const pincodeEnabled = customNavigator.isStandalone() && env.REACT_APP_PINCODE_USE === '1';

  useEffect(() => {
    const onInstall = () => {
      setPincode(null);
      clearPincodeSession();
    };

    window.addEventListener('appinstalled', onInstall);

    return () => {
      window.addEventListener('appinstalled', onInstall);
    };
  }, []);

  useLayoutEffect(() => {
    if (!pincodeEnabled) {
      return;
    }

    const savedPincode = getPincode();

    if (savedPincode && !isPincodeSessionStarted()) {
      setPincodeStage('pincode');
    }

    if (!savedPincode && !isPincodeSetupModalDisabled()) {
      setPincodeStage('setup');
    }
  }, [pincodeEnabled]);

  useEffect(() => {
    if (!pincodeEnabled) {
      return;
    }

    const visibilityChange = () => {
      const savedPincode = getPincode();

      if (document.hidden && savedPincode) {
        setPincodeStage('pincode');
      }
    };

    document.addEventListener('visibilitychange', visibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', visibilityChange);
    };
  }, [pincodeEnabled]);

  if (pincodeStage === 'setup') {
    return <PinCodeSetup />;
  }

  if (pincodeStage === 'pincode') {
    return <PinCode idleTimerRef={idleTimerRef} />;
  }

  const handleOnAction = () => {
    idleTimerRef.current?.reset();
  };

  const handleOnIdle = () => {
    setPincodeStage('pincode');
  };

  if (!pincodeEnabled) {
    return null;
  }

  return (
    <IdleTimer
      ref={(ref) => {
        idleTimerRef.current = ref;
      }}
      debounce={100}
      timeout={1000 * 60 * parseInt(env.REACT_APP_PINCODE_TIMER || '5', 10)}
      onIdle={handleOnIdle}
      onAction={handleOnAction}
      stopOnIdle
    />
  );
}

export default PinCodeInit;
