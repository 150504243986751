import {useEffect, useState} from 'react';

const useIsOnline = () => {
  const [isOnline, setIsOnline] = useState(() => window.navigator.onLine);

  useEffect(() => {
    window.addEventListener('online', () => {
      setIsOnline(true);
    });

    window.addEventListener('offline', () => {
      setIsOnline(false);
    });
  }, []);

  return isOnline;
};

export default useIsOnline;
