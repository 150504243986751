import {sample, createEffect, createEvent, createStore} from 'effector';

import config from '~/config';
import * as cityService from '~/services/cities';
import sponsorService from '~/services/sponsors';
import escortsService from '~/services/escorts';
import placesService from '~/services/places';
import * as forumsService from '~/services/forums/forums';
import locations from '~/services/locations';
import * as geolocationService from '~/services/geolocation';

import {$city} from './cities';

export const precacheData = createEvent('');

// fetch data when service worker is activated after fresh installation
const subscribeToServiceWorkerUpdates = async () => {
  const serviceWorkerRegistration = await window.navigator?.serviceWorker?.getRegistration();

  if (!serviceWorkerRegistration) {
    return;
  }

  let serviceWorker: ServiceWorker | null = null;

  if (serviceWorkerRegistration.installing) {
    serviceWorker = serviceWorkerRegistration.installing;
  } else if (serviceWorkerRegistration.waiting) {
    serviceWorker = serviceWorkerRegistration.waiting;
  } else if (serviceWorkerRegistration.active) {
    serviceWorker = serviceWorkerRegistration.active;
  }

  if (!serviceWorker) {
    return;
  }

  serviceWorker.addEventListener('statechange', () => {
    if (serviceWorker?.state === 'activated') {
      precacheData();
    }
  });
};

// start preloading data so that it will be available for offline mode (through service worker)
// the requests should not duplicate, bacause we have axios cache interceptor
const preloadDataForOfflineCacheFx = createEffect(async () => {
  try {
    // countries/states cache
    await geolocationService.getLocationByIp();
    await locations.getCountriesWithState();

    // location/lists cache
    const currentLocation = config.getCity();

    if (!currentLocation) {
      return;
    }

    const [, countryCode, stateName, cityName] = currentLocation.url.split('/');

    await Promise.all([
      cityService.getCityByUrl(
        countryCode,
        cityName === undefined ? undefined : stateName,
        cityName || stateName
      ),
      sponsorService.getPlace(currentLocation.id),
    ]);

    const escortTypes = await escortsService.getTypes(currentLocation.id);
    const placesTypes = await placesService.getTypes(currentLocation.id);

    const escortsPromises = escortTypes.map((escortType) =>
      escortsService.getEscortsByLocation(currentLocation.id, escortType.slug)
    );

    const placesPromises = placesTypes.map((placeType) =>
      placesService.getPlacesByLocation(currentLocation.id, placeType.id)
    );

    await Promise.all([
      ...escortsPromises,
      ...placesPromises,
      forumsService.getForums(currentLocation.id),
    ]);
  } catch (error) {
    console.error('Cannot preload data', error);
  }
});

// create store to track if data is precached, reset on city change
const precacheComplete = createStore(false)
  .on(preloadDataForOfflineCacheFx.done, () => true)
  .reset($city);

// trigger preloadDataForOfflineCacheFx on precacheData event or when city is changed
sample({
  source: {
    precacheComplete,
    pending: preloadDataForOfflineCacheFx.pending,
  },
  clock: [precacheData, $city],
  filter: (state) => {
    if (state.precacheComplete || state.pending) {
      return false;
    }

    return true;
  },
  target: preloadDataForOfflineCacheFx,
});

subscribeToServiceWorkerUpdates();
