import {createStore, createEffect, createEvent, sample, combine} from 'effector';

import logger from '~/utils/logger';
import {ForumTopicsResponse, getTopics} from '~/services/forums';
import {$forums, loadForumsFx} from '~/store/forums';
import {$city} from '~/store/cities';
import {createPaginationStore} from '~/components/common/Pagination';

const setLoading = createEvent();
export const resetPage = createEvent();
export const loadForumsForSlug = createEvent<string>();
export const loadTopics = createEvent<{
  forumId: number;
  offset: number;
  limit: number;
  search?: string;
}>();

export const loadTopicsFx = createEffect(getTopics);

export const {$pagination, resetPagingOptions} = createPaginationStore('forum-index');

export const $loading = createStore<boolean>(true)
  .on(setLoading, () => true)
  .on([loadTopicsFx.done, loadTopicsFx.fail], () => false)
  .reset(resetPage);

export const $topics = createStore<ForumTopicsResponse>({list: [], total: 0})
  .on(loadTopicsFx.doneData, (_, payload) => payload)
  .on(loadTopicsFx.fail, () => ({list: [], total: 0}));

loadTopicsFx.fail.watch(({error}) => {
  logger.error(error);
});

export const $currentForumSlug = createStore<string | null>(null).on(
  loadForumsForSlug,
  (_, forumSlug) => forumSlug
);

export const $currentForum = combine($forums, $currentForumSlug, (forums, forumSlug) => {
  return forums.find((forum) => forum.url === forumSlug) || null;
});

// reset paging
sample({
  clock: resetPage,
  target: resetPagingOptions,
});

sample({
  source: {
    city: $city,
    currentForum: $currentForum,
  },
  clock: loadForumsForSlug,
  filter: ({city, currentForum}) => Boolean(!currentForum && city),
  fn: ({city}) => city?.id || 0,
  target: loadForumsFx,
});

sample({
  source: {
    city: $city,
    loading: loadTopicsFx.pending,
  },
  clock: loadTopics,
  filter: ({city, loading}) => Boolean(city && !loading),
  fn: ({city}, {forumId, offset, limit, search}) => ({
    cityId: city?.id || 0,
    forumId,
    offset,
    limit,
    query: search,
  }),
  target: [setLoading, loadTopicsFx],
});
