import {FC, useEffect} from 'react';

import Modal from '~/components/common/Modal';

import {appendSBScript} from './helpers';
import ModalHotSearchContent from './components/ModalHotSearchContent';

export const MODAL_HOT_SEARCH = 'modal-hot-search';

const ModalHotSearch: FC = () => {
  useEffect(() => {
    appendSBScript();
  }, []);

  return (
    <Modal id={MODAL_HOT_SEARCH} title="Search at HOT.com">
      <ModalHotSearchContent />
    </Modal>
  );
};

export default ModalHotSearch;
