import {FC} from 'react';
import {Link} from 'react-router-dom';
import clsx from 'clsx';

import Icon from '~/components/icons';

import {SidebarMenuItemProps} from '../types';

const Button = ({children, ...props}: any) => (
  <button type="button" {...props}>
    {children}
  </button>
);

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  onClick,
  to,
  icon,
  disabled = false,
  children,
}) => {
  const Component = disabled ? 'div' : to ? Link : Button; // eslint-disable-line no-nested-ternary

  return (
    <li>
      <Component className={clsx('link p-y-15', {disabled})} to={to || '#'} onClick={onClick}>
        <div className="icon-with-title m-r-10">
          <div className="icon m-r-15 d-flex justify-content-center align-items-center green icon-desktop">
            <Icon id={icon} />
          </div>
          <span className="color-quaternary">{children}</span>
        </div>
      </Component>
    </li>
  );
};

export default SidebarMenuItem;
