import isMobile from 'is-mobile';
import UAParser from 'ua-parser-js';

const parser = new UAParser(window.navigator.userAgent);
const os = parser.getOS().name;
const isIpad = window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1;

export default {
  isStandalone() {
    const nav = window.navigator;
    const isSamsungBrowser = window.navigator.userAgent.includes('SamsungBrowser');

    return (
      window.matchMedia('(display-mode: standalone)').matches ||
      nav.standalone === true ||
      (isSamsungBrowser && window.innerHeight / window.screen.height > 0.86)
    );
  },

  isXiaomi() {
    return Boolean(window.navigator.userAgent.match(/(Mi|Redmi)\b/));
  },

  isAndroid() {
    return os === 'AndroidOS';
  },

  isIOS() {
    return os === 'iPadOS' || os === 'iOS' || isIpad;
  },

  isMobileSafari() {
    return Boolean(isMobile() && window.navigator.userAgent.includes('Safari'));
  },

  isChrome() {
    return parser.getEngine().name === 'Blink';
  },
};
