import axios from 'axios';

import {DEFAULT_ERROR_MESSAGE, AXIOS_ERROR_MAP} from './constants';

export const processRequestError = (error: Error) => {
  let message = DEFAULT_ERROR_MESSAGE;

  if (axios.isAxiosError(error)) {
    const code: string = error.code || '';

    if (AXIOS_ERROR_MAP[code]) {
      message = AXIOS_ERROR_MAP[code];
    }
  }

  return message;
};
