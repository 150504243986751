import {createStore, createEffect, createEvent, sample} from 'effector';

import {Sponsor} from '~/services/types';
import sponsorService from '~/services/sponsors';
import {$city} from '~/store/cities';
import logger from '~/utils/logger';
import {loadForumsFx} from '~/store/forums';

// sponsors are shuffled on every page load!
export const loadSponsors = createEvent('Fetch sponsors');
export const loadSponsorsFx = createEffect(sponsorService.getPlace);
export const $sponsors = createStore<Sponsor[]>([])
  .on(loadSponsorsFx.doneData, (_, payload) => payload)
  .on(loadSponsorsFx.fail, () => []);

loadSponsorsFx.fail.watch(({error}) => {
  logger.error(error);
});

sample({
  source: $city,
  clock: loadSponsors,
  filter: (city) => !!city?.id,
  fn: (city) => city?.id,
  target: loadSponsorsFx,
});

// fetch forums on city change
sample({
  clock: $city,
  filter: (city) => !!city?.id,
  fn: (city) => city?.id || 0,
  target: loadForumsFx,
});
