import {Suspense, useEffect, lazy} from 'react';
import {RouterProvider} from 'react-router-dom';
import type {Router} from '@remix-run/router';
import {useStore} from 'effector-react';

import {$globalLoader} from '~/store/common';
import {router, injectRouter} from '~/router';
import browserRouter from '~/router/browserRouter';
import {gaInit} from '~/utils/ga';
import OfflineBar from '~/layout/OfflineBar';
import {precacheData} from '~/store/precache';
import useIsOnline from '~/utils/useIsOnline';

import Loading from '../common/Loading';
import AppUpdate from '../system/AppUpdate';
import PageMeta from '../system/PageMeta';
import useMobileHeight from '../system/useMobileHeight';
import FreshChat from '../system/FreshChat';
import Loader from './components/Loader';
import {$loading, preloadData} from './store';
import PinCodeInit from '../common/PinCodeNG/PinCodeInit';

// workaround router circular dependency
// see https://github.com/remix-run/react-router/issues/9422
injectRouter(browserRouter);

gaInit();

const FlashChat = lazy(
  () => import(/* webpackChunkName: "FlashChat", webpackPreload: true */ './components/FlashChat')
);

const Bootstrap = ({testRouter}: {testRouter?: Router}) => {
  const globalLoader = useStore($globalLoader);
  const preloadPending = useStore($loading);
  const isOnline = useIsOnline();

  useEffect(() => {
    preloadData();
    precacheData();
  }, []);

  useMobileHeight();

  if (!router) {
    return null;
  }

  if (preloadPending) {
    return <Loader />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <PinCodeInit />
      <OfflineBar />
      <AppUpdate />
      <PageMeta />
      <FreshChat />
      <Suspense fallback={null}>{isOnline && <FlashChat />}</Suspense>
      <RouterProvider router={testRouter || router} />
      {globalLoader && <Loading />}
    </Suspense>
  );
};

export default Bootstrap;
