import Cookies from 'universal-cookie';

// is session without asking for pincode has started
export const isPincodeSessionStarted = () => {
  return window.sessionStorage.getItem('pincodeSession') === '1';
};

// start session without asking for pincode
export const startPincodeSession = () => {
  window.sessionStorage.setItem('pincodeSession', '1');
};

export const clearPincodeSession = () => {
  window.sessionStorage.removeItem('pincodeSession');
};

export const setPincode = (pincode: string | null) => {
  const key = 'pincode';
  const cookies = new Cookies();

  if (pincode) {
    localStorage.setItem(key, pincode);

    cookies.set(
      key,
      pincode,
      {path: '/', expires: new Date(Date.now() + 3600), maxAge: 30 * 86400} // 1 month
    );
  } else {
    localStorage.removeItem(key);

    cookies.set(key, [], {path: '/', maxAge: 0});
  }
};

export const getPincode = () => {
  const key = 'pincode';
  let pincode = null;
  let found = false;

  const lsValue = localStorage.getItem(key);
  if (lsValue && typeof lsValue === 'string') {
    pincode = lsValue;
    found = true;
  }

  // re-save pin code to all storages
  if (found) {
    setPincode(pincode);
  } else {
    const cookies = new Cookies();
    const cookie = cookies.get(key);
    if (cookie && typeof cookie === 'string') {
      pincode = cookie;
    }
  }

  return pincode;
};

export const isPincodeSetupModalDisabled = () => {
  return !!localStorage.getItem('disabledPincodeSetupModal');
};

export const disablePincodeSetupModal = () => {
  localStorage.setItem('disabledPincodeSetupModal', '1');
};
