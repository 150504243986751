import {useLocation} from 'react-router-dom';

import {EscortSlug} from '~/services/escorts';

const useEscortsRoute = (slug: EscortSlug) => {
  const location = useLocation();

  return location.pathname.includes(slug);
};

export default useEscortsRoute;
