import {FC, useEffect, useState} from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import {useStore} from 'effector-react';

import {Page} from '~/utils/getLink';
import {$currentLocation} from '~/store/currentLocation';

import Loading from '../../components/common/Loading';
import {getLocationByIp} from '../../services/geolocation';

const MainPage: FC = () => {
  const navigate = useNavigate();
  const currentLocation = useStore($currentLocation);

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const request = async () => {
      // Try to get stored location from localstorage
      if (currentLocation.url) {
        navigate(currentLocation.url);
        setLoading(false);
        return;
      }

      // Try to get user location from backend
      const geolocation = await getLocationByIp();

      setLoading(false);
      if (!geolocation) {
        return;
      }

      if (geolocation.url) {
        navigate(geolocation.url);
      }
    };
    request();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return <Navigate to={Page.AllLocations} />;
};

export default MainPage;
