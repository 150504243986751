import {lazyWithPreload} from '~/utils/lazyWithPreload';

export const AuthSignupConfirm = lazyWithPreload(
  () => import(/* webpackChunkName: 'Auth' */ './SignupConfirm')
);

export const AuthResetPassword = lazyWithPreload(
  () => import(/* webpackChunkName: 'Auth' */ './ResetPassword')
);

export const AuthSignIn = lazyWithPreload(() => import(/* webpackChunkName: 'Auth' */ './Signin'));

export const AuthSignUp = lazyWithPreload(() => import(/* webpackChunkName: 'Auth' */ './Signup'));

export const Security2FA = lazyWithPreload(
  () => import(/* webpackChunkName: 'Auth' */ './Security2FA')
);
