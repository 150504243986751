import {useStore} from 'effector-react';
import {Helmet, HelmetData} from 'react-helmet-async';

import {$title} from '~/store/common';

const helmetData = new HelmetData({});

const PageMeta = () => {
  const title = useStore($title);
  const fullTitle = title ? `${title} — ` : '';

  return (
    <Helmet helmetData={helmetData}>
      <title>{fullTitle}Find Escorts, Strip Clubs, Sex Shops | Adult Search Engine</title>
    </Helmet>
  );
};

export default PageMeta;
