import {FC} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useStore} from 'effector-react';
import clsx from 'clsx';

import config from '~/config';
import url from '~/utils/url';
import {Page} from '~/utils/getLink';
import {getToken} from '~/services/auth';
import {$translationLanguage} from '~/store/translationLanguage';
import {setShowSidebar} from '~/store/common';
import {$currentLocation} from '~/store/currentLocation';
import Icon, {Icons} from '~/components/icons';
import {showModal} from '~/components/common/Modal';
import useIsOnline from '~/utils/useIsOnline';

import './styles.scss';

const Header: FC = () => {
  const navigate = useNavigate();
  const translationLanguage = useStore($translationLanguage);
  let currentLocation = useStore($currentLocation);
  const isOnline = useIsOnline();

  let fixed = false;
  let transparent = false;
  let showLanguage = true;
  let showLocation = true;
  let showAuth = true;

  // global: current city object (from global or LS)
  if (!currentLocation || !Object.keys(currentLocation).length) {
    currentLocation = config.getCity();
    config.setCity(currentLocation);
  }

  // local header vars
  let showBackLink = !(
    url.isPage('home') ||
    url.isPage('signin') ||
    url.isPage('signup') ||
    url.isPage('reset') ||
    url.isPage('all-locations')
  );
  let showLogo =
    url.isPage('home') ||
    url.isPage('signin') ||
    url.isPage('signup') ||
    url.isPage('reset') ||
    url.isPage('all-locations');

  if (!window.history.length) {
    showBackLink = false;
  }

  // special cases
  if (url.isPage('topups')) {
    fixed = true;
    transparent = true;
    showLogo = false;
    showLanguage = false;
    showLocation = false;
    showAuth = false;
  }

  const handleClickBack = () => {
    if (window.history.length === 1) {
      navigate(Page.AllLocations);

      return;
    }

    navigate(-1);
  };

  const handleOpenClick = () => {
    showModal('language-modal');
  };

  return (
    <header
      className={clsx({
        'bg-transparent': transparent,
        fixed,
        offline: !isOnline,
      })}
    >
      <div
        className={clsx(
          'header-container',
          showBackLink ? 'justify-content-end' : 'justify-content-center'
        )}
      >
        {/* LINK BACK */}
        {showBackLink && (
          <div className="header-back-btn-container m-r-auto m-l-3">
            <button
              type="button"
              onClick={handleClickBack}
              className="header-back-btn btn-link color-white"
              name="Navigate back"
            >
              <Icon id={Icons.header_back} className="icon_header_back" />
            </button>
          </div>
        )}

        {/* LOGO */}
        {showLogo && (
          <Link className="brand m-r-auto" to={Page.Home}>
            <span className="brand__text notranslate">AS</span>
          </Link>
        )}

        {/* LANGUAGES */}
        {showLanguage && (
          <div id="language-dropdown" className="language-dropdown header-language__dropdown">
            <button type="button" onClick={handleOpenClick}>
              <span className="m-r-5">{translationLanguage}</span>
            </button>
            <Icon id={Icons.dropdown} className="icon_dropdown" />
          </div>
        )}

        {/* Location search */}
        {showLocation && (
          <div className="header-location">
            <Link to={Page.AllLocations} className="header-location_label">
              <Icon id={Icons.place_location} className="icon_place_location m-r-5" />
              {currentLocation.name}
            </Link>
          </div>
        )}

        {/* NOT LOGGED - LOGIN BTN */}
        {showAuth && !getToken() && (
          <Link to={Page.SignUp} className="header-signin">
            Sign Up
          </Link>
        )}

        {/* LOGGED - HAMBURGER MENU */}
        <button
          type="button"
          className="burger-menu-toggle"
          onClick={() => setShowSidebar(true)}
          name="Toggle sidebar"
        >
          <Icon id={Icons.burger} className="icon_burger" />
        </button>
      </div>
    </header>
  );
};

export default Header;
