import {createStore, createEvent} from 'effector';

import {TCurrentLocation} from '~/types/common';

// Like home location, shows at top of the header
export const setCurrentLocation = createEvent<TCurrentLocation>('Set current location');
export const $currentLocation = createStore<TCurrentLocation>({} as TCurrentLocation).on(
  setCurrentLocation,
  (state, payload) => payload
);
