export const languages = [
  {
    flag: '🇬🇧',
    name: 'English',
    id: 'en',
  },
  {
    flag: '🇪🇸',
    name: 'Spanish',
    id: 'es',
  },
  {
    flag: '🇿🇦',
    name: 'Afrikaans',
    id: 'af',
  },
  {
    flag: '🇪🇹',
    name: 'Amharic',
    id: 'am',
  },
  {
    flag: '🇸🇦',
    name: 'Arabic',
    id: 'ar',
  },
  {
    flag: '🇧🇴',
    name: 'Aymara',
    id: 'ay',
  },
  {
    flag: '🇦🇿',
    name: 'Azerbaijani',
    id: 'az',
  },
  {
    flag: '🇧🇾',
    name: 'Belarusian',
    id: 'be',
  },
  {
    flag: '🇧🇬',
    name: 'Bulgarian',
    id: 'bg',
  },
  {
    flag: '🇻🇺',
    name: 'Bislama',
    id: 'bi',
  },
  {
    flag: '🇧🇩',
    name: 'Bengali',
    id: 'bn',
  },
  {
    flag: '🇧🇦',
    name: 'Bosnian',
    id: 'bs',
  },
  {
    flag: '🇦🇩',
    name: 'Catalan',
    id: 'ca',
  },
  {
    flag: '🇬🇺',
    name: 'Chamorro',
    id: 'ch',
  },
  {
    flag: '🇨🇿',
    name: 'Czech',
    id: 'cs',
  },
  {
    flag: '🇩🇰',
    name: 'Danish',
    id: 'da',
  },
  {
    flag: '🇩🇪',
    name: 'German',
    id: 'de',
  },
  {
    flag: '🇲🇻',
    name: 'Divehi',
    id: 'dv',
  },
  {
    flag: '🇧🇹',
    name: 'Dzongkha',
    id: 'dz',
  },
  {
    flag: '🇬🇷',
    name: 'Greek',
    id: 'el',
  },
  {
    flag: '🇪🇪',
    name: 'Estonian',
    id: 'et',
  },
  {
    flag: '🇪🇸',
    name: 'Basque',
    id: 'eu',
  },
  {
    flag: '🇮🇷',
    name: 'Persian',
    id: 'fa',
  },
  {
    flag: '🇬🇳',
    name: 'Peul',
    id: 'ff',
  },
  {
    flag: '🇫🇮',
    name: 'Finnish',
    id: 'fi',
  },
  {
    flag: '🇫🇯',
    name: 'Fijian',
    id: 'fj',
  },
  {
    flag: '🇫🇴',
    name: 'Faroese',
    id: 'fo',
  },
  {
    flag: '🇫🇷',
    name: 'French',
    id: 'fr',
  },
  {
    flag: '🇮🇪',
    name: 'Irish',
    id: 'ga',
  },
  {
    flag: '🇪🇸',
    name: 'Galician',
    id: 'gl',
  },
  {
    flag: '🇵🇾',
    name: 'Guarani',
    id: 'gn',
  },
  {
    flag: '🇮🇲',
    name: 'Manx',
    id: 'gv',
  },
  {
    flag: '🇮🇱',
    name: 'Hebrew',
    id: 'he',
  },
  {
    flag: '🇮🇳',
    name: 'Hindi',
    id: 'hi',
  },
  {
    flag: '🇭🇷',
    name: 'Croatian',
    id: 'hr',
  },
  {
    flag: '🇭🇹',
    name: 'Haitian',
    id: 'ht',
  },
  {
    flag: '🇭🇺',
    name: 'Hungarian',
    id: 'hu',
  },
  {
    flag: '🇦🇲',
    name: 'Armenian',
    id: 'hy',
  },
  {
    flag: '🇮🇩',
    name: 'Indonesian',
    id: 'id',
  },
  {
    flag: '🇮🇸',
    name: 'Icelandic',
    id: 'is',
  },
  {
    flag: '🇮🇹',
    name: 'Italian',
    id: 'it',
  },
  {
    flag: '🇯🇵',
    name: 'Japanese',
    id: 'ja',
  },
  {
    flag: '🇬🇪',
    name: 'Georgian',
    id: 'ka',
  },
  {
    flag: '🇨🇩',
    name: 'Kongo',
    id: 'kg',
  },
  {
    flag: '🇰🇿',
    name: 'Kazakh',
    id: 'kk',
  },
  {
    flag: '🇬🇱',
    name: 'Greenlandic',
    id: 'kl',
  },
  {
    flag: '🇰🇭',
    name: 'Cambodian',
    id: 'km',
  },
  {
    flag: '🇰🇷',
    name: 'Korean',
    id: 'ko',
  },
  {
    flag: '🇮🇶',
    name: 'Kurdish',
    id: 'ku',
  },
  {
    flag: '🇰🇬',
    name: 'Kirghiz',
    id: 'ky',
  },
  {
    flag: '🇻🇦',
    name: 'Latin',
    id: 'la',
  },
  {
    flag: '🇱🇺',
    name: 'Luxembourgish',
    id: 'lb',
  },
  {
    flag: '🇨🇩',
    name: 'Lingala',
    id: 'ln',
  },
  {
    flag: '🇱🇦',
    name: 'Laotian',
    id: 'lo',
  },
  {
    flag: '🇱🇹',
    name: 'Lithuanian',
    id: 'lt',
  },
  {
    flag: '🇱🇻',
    name: 'Latvian',
    id: 'lv',
  },
  {
    flag: '🇲🇬',
    name: 'Malagasy',
    id: 'mg',
  },
  {
    flag: '🇲🇭',
    name: 'Marshallese',
    id: 'mh',
  },
  {
    flag: '🇳🇿',
    name: 'Maori',
    id: 'mi',
  },
  {
    flag: '🇲🇰',
    name: 'Macedonian',
    id: 'mk',
  },
  {
    flag: '🇲🇳',
    name: 'Mongolian',
    id: 'mn',
  },
  {
    flag: '🇲🇾',
    name: 'Malay',
    id: 'ms',
  },
  {
    flag: '🇲🇹',
    name: 'Maltese',
    id: 'mt',
  },
  {
    flag: '🇲🇲',
    name: 'Burmese',
    id: 'my',
  },
  {
    flag: '🇳🇷',
    name: 'Nauruan',
    id: 'na',
  },
  {
    flag: '🇿🇼',
    name: 'North Ndebele',
    id: 'nd',
  },
  {
    flag: '🇳🇵',
    name: 'Nepali',
    id: 'ne',
  },
  {
    flag: '🇳🇱',
    name: 'Dutch',
    id: 'nl',
  },
  {
    flag: '🇳🇴',
    name: 'Norwegian Nynorsk',
    id: 'nn',
  },
  {
    flag: '🇳🇴',
    name: 'Norwegian',
    id: 'no',
  },
  {
    flag: '🇿🇦',
    name: 'South Ndebele',
    id: 'nr',
  },
  {
    flag: '🇲🇼',
    name: 'Chichewa',
    id: 'ny',
  },
  {
    flag: '🇪🇸',
    name: 'Occitan',
    id: 'oc',
  },
  {
    flag: '🇨🇼',
    name: 'Panjabi / Punjabi',
    id: 'pa',
  },
  {
    flag: '🇵🇱',
    name: 'Polish',
    id: 'pl',
  },
  {
    flag: '🇦🇫',
    name: 'Pashto',
    id: 'ps',
  },
  {
    flag: '🇵🇹',
    name: 'Portuguese',
    id: 'pt',
  },
  {
    flag: '🇧🇴',
    name: 'Quechua',
    id: 'qu',
  },
  {
    flag: '🇧🇮',
    name: 'Kirundi',
    id: 'rn',
  },
  {
    flag: '🇷🇴',
    name: 'Romanian',
    id: 'ro',
  },
  {
    flag: '🇷🇺',
    name: 'Russian',
    id: 'ru',
  },
  {
    flag: '🇷🇼',
    name: 'Rwandi',
    id: 'rw',
  },
  {
    flag: '🇨🇫',
    name: 'Sango',
    id: 'sg',
  },
  {
    flag: '🇱🇰',
    name: 'Sinhalese',
    id: 'si',
  },
  {
    flag: '🇸🇰',
    name: 'Slovak',
    id: 'sk',
  },
  {
    flag: '🇸🇮',
    name: 'Slovenian',
    id: 'sl',
  },
  {
    flag: '🇼🇸',
    name: 'Samoan',
    id: 'sm',
  },
  {
    flag: '🇿🇼',
    name: 'Shona',
    id: 'sn',
  },
  {
    flag: '🇸🇴',
    name: 'Somalia',
    id: 'so',
  },
  {
    flag: '🇦🇱',
    name: 'Albanian',
    id: 'sq',
  },
  {
    flag: '🇷🇸',
    name: 'Serbian',
    id: 'sr',
  },
  {
    flag: '🇸🇿',
    name: 'Swati',
    id: 'ss',
  },
  {
    flag: '🇱🇸',
    name: 'Southern Sotho',
    id: 'st',
  },
  {
    flag: '🇸🇪',
    name: 'Swedish',
    id: 'sv',
  },
  {
    flag: '🇹🇿',
    name: 'Swahili',
    id: 'sw',
  },
  {
    flag: '🇱🇰',
    name: 'Tamil',
    id: 'ta',
  },
  {
    flag: '🇹🇯',
    name: 'Tajik',
    id: 'tg',
  },
  {
    flag: '🇹🇭',
    name: 'Thai',
    id: 'th',
  },
  {
    flag: '🇪🇷',
    name: 'Tigrinya',
    id: 'ti',
  },
  {
    flag: '🇹🇲',
    name: 'Turkmen',
    id: 'tk',
  },
  {
    flag: '🇧🇼',
    name: 'Tswana',
    id: 'tn',
  },
  {
    flag: '🇹🇴',
    name: 'Tonga',
    id: 'to',
  },
  {
    flag: '🇹🇷',
    name: 'Turkish',
    id: 'tr',
  },
  {
    flag: '🇿🇦',
    name: 'Tsonga',
    id: 'ts',
  },
  {
    flag: '🇺🇦',
    name: 'Ukrainian',
    id: 'uk',
  },
  {
    flag: '🇵🇰',
    name: 'Urdu',
    id: 'ur',
  },
  {
    flag: '🇺🇿',
    name: 'Uzbek',
    id: 'uz',
  },
  {
    flag: '🇿🇦',
    name: 'Venda',
    id: 've',
  },
  {
    flag: '🇻🇳',
    name: 'Vietnamese',
    id: 'vi',
  },
  {
    flag: '🇿🇦',
    name: 'Xhosa',
    id: 'xh',
  },
  {
    flag: '🇨🇳',
    name: 'Chinese',
    id: 'zh',
  },
  {
    flag: '🇿🇦',
    name: 'Zulu',
    id: 'zu',
  },
];
