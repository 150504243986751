import {lazyWithPreload} from '~/utils/lazyWithPreload';

export const Faq = lazyWithPreload(() => import(/* webpackChunkName: 'Faq' */ './Faq'));

export const FaqAdvertiser = lazyWithPreload(
  () => import(/* webpackChunkName: 'Faq' */ './Advertiser')
);

export const FaqEscortAgency = lazyWithPreload(
  () => import(/* webpackChunkName: 'Faq' */ './EscortAgency')
);

export const FaqIncident = lazyWithPreload(
  () => import(/* webpackChunkName: 'Faq' */ './Incident')
);

export const FaqRobbed = lazyWithPreload(() => import(/* webpackChunkName: 'Faq' */ './Robbed'));

export const FaqLawEnforcement = lazyWithPreload(
  () => import(/* webpackChunkName: 'Faq' */ './LawEnforcement')
);
