import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import {signInFx, logout} from '~/store/auth';
import {getToken} from '~/services/auth';
import adbuild from '~/services/adbuild';

/* the module is designed for automatic login from the admin panel redirect */
const useImpersonatedAuth = () => {
  const {search} = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const userId = query.get('user_id');
    const token = query.get('token');

    if (!userId || !token) {
      return;
    }

    if (getToken()) {
      logout();
    }

    adbuild.clearCookie(true);

    signInFx({
      user_id: userId,
      token,
    });
  }, [search]);
};

export default useImpersonatedAuth;
