import {RouteObject} from 'react-router';

import Forums from './Forums';
import ForumIndex from './ForumIndex';
import ForumTopic from './ForumTopic';
import ForumTopicNew from './ForumTopicNew';
import {loadForumsForSlug} from './ForumIndex/store';
import {loadTopicById} from './ForumTopic/store';

export const ForumsList: RouteObject = {
  path: 'sex-forum',
  element: <Forums />,
};

export const ForumsRoute: RouteObject = {
  path: 'sex-forum/:forumSlug',
  loader: ({params: {forumSlug}}) => {
    if (forumSlug) {
      loadForumsForSlug(forumSlug);
    }

    return null;
  },
  shouldRevalidate: ({currentParams, nextParams}) => {
    if (currentParams.forumSlug !== nextParams.forumSlug) {
      return true;
    }

    return false;
  },
  children: [
    {
      index: true,
      element: <ForumIndex />,
    },
    {
      path: 'new-topic',
      element: <ForumTopicNew />,
    },
    {
      path: 'topic/:topicId',
      element: <ForumTopic />,
      loader: ({params: {topicId}}) => {
        if (topicId) {
          loadTopicById(topicId);
        }

        return null;
      },
      shouldRevalidate: ({currentParams, nextParams}) => {
        if (currentParams.topicId !== nextParams.topicId) {
          return true;
        }

        return false;
      },
    },
  ],
};
