import {FC, useEffect, useState, memo, lazy, Suspense} from 'react';
import clsx from 'clsx';

import {PaginationProps} from './types';
import {DEFAULT_LIMITS} from './constants';
import './styles.scss';

const ReactPaginate = lazy(() => import('react-paginate'));

const Pagination: FC<PaginationProps> = ({
  className,
  total,
  onChange,
  offset = 0,
  limit = 10,
  showSinglePage = true,
  limits = DEFAULT_LIMITS,
}) => {
  const [page, setPage] = useState(Math.floor(offset / limit));
  const pageCount = Math.ceil(total / limit);

  // set page if offset is changed from the outside
  useEffect(() => {
    const currOffset = page * limit;

    if (offset !== undefined && currOffset !== offset) {
      setPage(Math.floor(offset / limit));
    }
  }, [offset]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickLimit = (_limit: number) => {
    if (_limit === limit) {
      return;
    }

    setPage(0);

    onChange({
      total,
      limit: _limit,
      page: 0,
      offset: 0,
    });
  };

  const handlePageChange = (event: any) => {
    setPage(event.selected);

    onChange({
      total,
      limit,
      page: event.selected,
      offset: event.selected * limit,
    });
  };

  if (!showSinglePage && total <= limits[0]) {
    return null;
  }

  return (
    <Suspense fallback={<div />}>
      <div className={className}>
        <ReactPaginate
          className="pages"
          breakLabel="..."
          nextLabel=""
          onPageChange={handlePageChange}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          previousLabel=""
          forcePage={page}
          disableInitialCallback
        />

        <div className="limits">
          <span>Show me</span>
          <div>
            {limits.map((_limit) => (
              <button
                key={_limit}
                onClick={() => handleClickLimit(_limit)}
                type="button"
                className={clsx('limits__button', {'limits__button--active': _limit === limit})}
              >
                {_limit}
              </button>
            ))}
          </div>
          <span>per page</span>
        </div>
      </div>
    </Suspense>
  );
};

export default memo(Pagination);
