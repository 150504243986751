import logger from '~/utils/logger';

export const purgeCache = (cacheName: string) => {
  if (!window.caches || !cacheName) {
    return;
  }

  logger.debug(`Purging cache: ${cacheName}`);

  return window.caches.delete(cacheName);
};
