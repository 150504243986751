import {Page, PageRegExp} from '~/utils/getLink';

export const MODAL_HOT_CAMPAIGN_ID = 'modal-hot-campaing';
export const MODAL_HOT_ADS_PAUSED_ID = 'modal-hot-ads-paused';

export const HOT_PROTECTED_PAGES = [
  Page.Payments,
  Page.ChangeEmail,
  Page.ChangePassword,
  Page.ChangeSettings,
  Page.ChangeUsername,
  Page.ChatSettings,
  Page.ConfirmEmail,
  Page.ChangeToAgency,
  Page.UnverifiedInfo,
  Page.Topups,
  Page.Adbuild,
  Page.Adbuild2,
  Page.Adbuild3,
  Page.Chat,
];

export const HOT_PROTECTED_PAGES_REGEXP = [
  PageRegExp.AdChangeLocation,
  PageRegExp.AdEditThumbnail,
  PageRegExp.AdDuplicate,
  PageRegExp.AdEdit,
  PageRegExp.AdStep3,
  PageRegExp.AdBuyEcortSponsor,
  PageRegExp.AdBuyEcortSide,
  PageRegExp.AdBuyEscortSticky,
];
