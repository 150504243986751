import {Page} from './getLink';

export const isExternalLink = (link: string) =>
  typeof link === 'string' && /^https?:\/\//.test(link);

export default {
  getPlaceBuyLink(cityUrl: string, placeId: number) {
    return `${cityUrl}/buy/${placeId}`;
  },

  getPlaceEditLink(cityUrl: string, placeId: number) {
    return `${cityUrl}/add/${placeId}`;
  },

  getPlaceUploadLink(cityUrl: string, placeId: number, type: string) {
    return `${cityUrl}/upload/${placeId}/${type}`;
  },

  getPlaceLink(cityUrl: string, categoryUrl: string, nameSlug: string, placeId: number) {
    return categoryUrl ? `${cityUrl}/${categoryUrl}/${nameSlug}/${placeId}` : '/#';
  },

  getEscortEditLink(escortId: number) {
    return `${Page.MyAds}/${escortId}/edit`;
  },

  getDialogUrl(chatDialogId: string) {
    return `${Page.Chat}/${chatDialogId}`;
  },

  getFooterActive() {
    let active = window.location.pathname;

    if (this.isPage('home')) {
      active = 'home';
    } else if (this.isPage('myposts')) {
      active = 'myposts';
    } else if (this.isPage('adbuild')) {
      active = 'adbuild';
    } else if (this.isPage('signin')) {
      active = 'signin';
    } else if (this.isPage('account')) {
      active = 'account';
    } else if (this.isPage('chat')) {
      active = 'chat';
    }

    return active;
  },

  isPage(page: string) {
    const uri = window.location.pathname;

    if (page === 'home' && (uri === '/' || !uri)) {
      return true;
    }
    if (page === 'myposts' && uri === Page.MyAds) {
      return true;
    }
    if (page === 'adbuild' && uri.includes('adbuild')) {
      return true;
    }
    if (page === 'adbuild1' && uri === '/adbuild') {
      return true;
    }
    if (page === 'chat' && uri.includes('/chat')) {
      return true;
    }
    if (page === 'signin' && uri === '/account/signin') {
      return true;
    }
    if (page === 'signup' && uri === '/account/signup') {
      return true;
    }
    if (page === 'reset' && uri === '/account/reset') {
      return true;
    }
    if (page === 'payment' && uri === '/payment/pay') {
      return true;
    }
    if (page === 'account' && uri.includes('account')) {
      return true;
    }
    if (page === 'topups' && uri === '/classifieds/topups') {
      return true;
    }
    if (page === '2fa' && uri === '/account/2fa') {
      return true;
    }
    if (page === 'all-locations' && uri === '/all-locations') {
      return true;
    }
    if (page === 'profile-place' && uri.match(/\/[^/]+\/[^/]+\/[^/]+\/\d+/)) {
      return true;
    }

    if (page === 'change-settings' && uri === '/account/change-settings') {
      return true;
    }

    return false;
  },
};
