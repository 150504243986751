import {FC} from 'react';
import {Navigate} from 'react-router-dom';

import {getToken} from '~/services/auth';
import {Page} from '~/utils/getLink';

import {PrivateRouteProps} from './types';

const PrivateRoute: FC<PrivateRouteProps> = ({children}) => {
  if (!getToken()) {
    const qs = new URLSearchParams({origin: window.location.pathname});

    return <Navigate to={`${Page.SignIn}?${qs}`} />;
  }

  return children;
};

export default PrivateRoute;
