import {FC, useEffect} from 'react';

import Icon, {Icons} from '~/components/icons';

import {startSB} from '../helpers';
import '../styles.scss';

const ModalHotSearchContent: FC = () => {
  useEffect(() => {
    startSB();
  }, []);

  // container for inject hot iframe
  return (
    <div id="hot_search_bar_62d19f12ca4a2" className="hot_search_bar">
      <div className="modal-hot-search-content__loader">
        <Icon className="loader-icon" id={Icons.loader} />
      </div>
    </div>
  );
};

export default ModalHotSearchContent;
