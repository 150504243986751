import {createEvent, sample, combine, restore} from 'effector';

import {refreshAccountData, loadAccountDataFx} from '~/store/account';

export const preloadData = createEvent('Preload application data');
export const setPreloadPending = createEvent<boolean>();
export const resetAccountPending = createEvent();
export const $accountDataPending = restore(setPreloadPending, true).reset(resetAccountPending);

loadAccountDataFx.done.watch(() => setPreloadPending(false));

sample({
  clock: preloadData,
  target: refreshAccountData,
});

// TODO: collect errors from effects and trigger error boundary

export const $loading = combine($accountDataPending, (pendingPreload) => pendingPreload);
