import {chompSlash} from '~/utils/parsers';
import {TCurrentLocation} from '~/types/common';

import {setCurrentLocation} from '../store/currentLocation';
import env from './appEnv';

export default {
  getImageServer() {
    return chompSlash(env.REACT_APP_AS_API_URL) || '';
  },

  /**
   * Get image base url
   * @param string (avatar|escort|place)
   * @returns {string}
   */
  getImagePath(type: string) {
    let url = this.getImageServer();

    switch (type) {
      case 'avatar':
        url += '/images/avatar';
        break;
      case 'escort':
        url += '/medias/classifieds';
        break;
      case 'escortThumbnail':
        // url += '/medias/classifieds/t';
        url += '/medias/classifieds';
        break;
      case 'place':
        url += '/medias/place';
        break;
      case 'placeThumbnail':
        url += '/medias/place/t';
        break;
      default:
        url = '';
    }

    return url;
  },

  getVideoPath(type: string) {
    let url = this.getImageServer();

    switch (type) {
      case 'escort':
        url += '/medias/classifieds/video';
        break;
      default:
        url = '';
    }

    return url;
  },

  getVideoPlacePath(country: string) {
    const url = this.getImageServer();

    return `${url}/medias/videos/${country}`;
  },

  getEmailSupport() {
    return env.REACT_APP_EMAIL_SUPPORT;
  },

  getProductionServer() {
    return env.REACT_APP_PWA_URL;
  },

  getServer() {
    let url = 'prod';

    if (
      window.location.hostname.includes('127.0.0.1') ||
      window.location.hostname.includes('localhost')
    ) {
      url = 'local';
    }

    return url;
  },

  /**
   * Get escort upgrade type(s) as used in payment form url
   * @param type (sponsor|side|stickyWeek|stickyMonth)
   */
  getEscortUpgradeType(type?: string, locationIds = true) {
    const types = ['escortSponsor', 'escortSide', 'escortStickyWeek', 'escortStickyMonth'];

    // append suffix to each key
    if (locationIds) {
      types.forEach((_, index) => {
        types[index] += 'LocationIds';
      });
    }

    switch (type) {
      case 'sponsor':
        return types[0];
      case 'side':
        return types[1];
      case 'stickyWeek':
        return types[2];
      case 'stickyMonth':
        return types[3];
      default:
        return types;
    }
  },

  getPaymentUrl(params: Record<string, string | number>) {
    const queryUrl = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');

    return `/payment/pay?${queryUrl}`;
  },

  /**
   * Get global title for each component
   * based on list of components in App.jsx
   */
  getTitle(component: {constructor: {name: string}}) {
    const {name} = component.constructor;

    const titles: any = {
      Account: 'My AS',
      AccountChangeEmail: 'Change Email',
      AccountConfirmEmail: 'Confirm Email',
      AccountChangePassword: 'Change Password',
      AccountChangeSettings: 'Settings',
      AccountChangeUsername: 'Edit Username',
      AccountClose: 'Close Account',
      AccountPayments: 'Payments',
      AccountWire: 'Wire Sent Form',
      SignupConfirm: 'Sign Up Confirmed',
      ResetPassword: 'Reset Password',
      Step1: 'Post Ad',
      Step2: 'Post Ad',
      Confirm: 'Phone Confirmation',
      BuyTopups: 'Buy TopUps',
      MyAds: 'My Ads',
      AdDuplicate: 'Duplicate Ad',
      AdChangeLocation: 'Change Ad Location',
      AdEditThumbnail: 'Edit Ad Thumbnails',
      ContactUs: 'Contact Us',
      PaymentForm: 'Payment Details',
      PaymentRecharge: 'Recharge Balance',
      PrivacyPolicy: 'Privacy Policy',
      TermsOfService: 'Terms Of Service',
      PlaceBuy: 'Choose Advertising Package',
      PlaceUpload: 'Upload file to place',
      ForumTopicNew: 'New Forum Topic',
      PlaceAdd: 'Edit Your Business',
      PlaceReview: 'Add Review',
    };

    return titles[name] ? titles[name] : false;
  },

  getScreenWidth() {
    return Math.max(window.screen.width, window.innerWidth);
  },

  getHoursFrom(key: any = false, value: any = false) {
    const hours = [
      {value: '0', name: '12:00AM'},
      {value: '30', name: '12:30AM'},
      {value: '60', name: '01:00AM'},
      {value: '90', name: '01:30AM'},
      {value: '120', name: '02:00AM'},
      {value: '150', name: '02:30AM'},
      {value: '180', name: '03:00AM'},
      {value: '210', name: '03:30AM'},
      {value: '240', name: '04:00AM'},
      {value: '270', name: '04:30AM'},
      {value: '300', name: '05:00AM'},
      {value: '330', name: '05:30AM'},
      {value: '360', name: '06:00AM'},
      {value: '390', name: '06:30AM'},
      {value: '420', name: '07:00AM'},
      {value: '450', name: '07:30AM'},
      {value: '480', name: '08:00AM'},
      {value: '510', name: '08:30AM'},
      {value: '540', name: '09:00AM'},
      {value: '570', name: '09:30AM'},
      {value: '600', name: '10:00AM'},
      {value: '630', name: '10:30AM'},
      {value: '660', name: '11:00AM'},
      {value: '690', name: '11:30AM'},
      {value: '720', name: '12:00PM'},
      {value: '750', name: '12:30PM'},
      {value: '780', name: '01:00PM'},
      {value: '810', name: '01:30PM'},
      {value: '840', name: '02:00PM'},
      {value: '870', name: '02:30PM'},
      {value: '900', name: '03:00PM'},
      {value: '930', name: '03:30PM'},
      {value: '960', name: '04:00PM'},
      {value: '990', name: '04:30PM'},
      {value: '1020', name: '05:00PM'},
      {value: '1050', name: '05:30PM'},
      {value: '1080', name: '06:00PM'},
      {value: '1110', name: '06:30PM'},
      {value: '1140', name: '07:00PM'},
      {value: '1170', name: '07:30PM'},
      {value: '1200', name: '08:00PM'},
      {value: '1230', name: '08:30PM'},
      {value: '1260', name: '09:00PM'},
      {value: '1290', name: '09:30PM'},
      {value: '1320', name: '10:00PM'},
      {value: '1350', name: '10:30PM'},
      {value: '1380', name: '11:00PM'},
      {value: '1410', name: '11:30PM'},
    ];

    if (key) {
      const result = hours.filter((hour) => {
        return parseInt(hour.value, 10) === parseInt(key, 10);
      });
      return result.length ? result[0].name : false;
    }
    if (value) {
      const result = hours.filter((hour) => {
        return hour.name === value;
      });
      return result.length ? result[0].value : false;
    }
    return hours;
  },

  getHoursTo(key: any = false, value: any = false) {
    const hours = [
      {value: '1470', name: '12:30AM'},
      {value: '1500', name: '01:00AM'},
      {value: '1530', name: '01:30AM'},
      {value: '1560', name: '02:00AM'},
      {value: '1590', name: '02:30AM'},
      {value: '1620', name: '03:00AM'},
      {value: '1650', name: '03:30AM'},
      {value: '1680', name: '04:00AM'},
      {value: '1710', name: '04:30AM'},
      {value: '1740', name: '05:00AM'},
      {value: '1770', name: '05:30AM'},
      {value: '1800', name: '06:00AM'},
      {value: '1830', name: '06:30AM'},
      {value: '1860', name: '07:00AM'},
      {value: '1890', name: '07:30AM'},
      {value: '1920', name: '08:00AM'},
      {value: '1950', name: '08:30AM'},
      {value: '1980', name: '09:00AM'},
      {value: '2010', name: '09:30AM'},
      {value: '2040', name: '10:00AM'},
      {value: '2070', name: '10:30AM'},
      {value: '2100', name: '11:00AM'},
      {value: '2130', name: '11:30AM'},
      {value: '720', name: '12:00PM'},
      {value: '750', name: '12:30PM'},
      {value: '780', name: '01:00PM'},
      {value: '810', name: '01:30PM'},
      {value: '840', name: '02:00PM'},
      {value: '870', name: '02:30PM'},
      {value: '900', name: '03:00PM'},
      {value: '930', name: '03:30PM'},
      {value: '960', name: '04:00PM'},
      {value: '990', name: '04:30PM'},
      {value: '1020', name: '05:00PM'},
      {value: '1050', name: '05:30PM'},
      {value: '1080', name: '06:00PM'},
      {value: '1110', name: '06:30PM'},
      {value: '1140', name: '07:00PM'},
      {value: '1170', name: '07:30PM'},
      {value: '1200', name: '08:00PM'},
      {value: '1230', name: '08:30PM'},
      {value: '1260', name: '09:00PM'},
      {value: '1290', name: '09:30PM'},
      {value: '1320', name: '10:00PM'},
      {value: '1350', name: '10:30PM'},
      {value: '1380', name: '11:00PM'},
      {value: '1410', name: '11:30PM'},
      {value: '1440', name: '12:00AM'},
    ];

    if (key) {
      const result = hours.filter((hour) => {
        return parseInt(hour.value, 10) === parseInt(key, 10);
      });
      return result.length ? result[0].name : false;
    }
    if (value) {
      const result = hours.filter((hour) => {
        return hour.name === value;
      });
      return result.length ? result[0].value : false;
    }
    return hours;
  },

  /**
   * Use or not email with escort
   * @returns {boolean}
   */
  useEscortEmail() {
    return false;
  },

  /**
   * Save current city for header to LS + global state
   * @param location
   */
  setCity(location: any) {
    if (location && typeof location === 'object') {
      const city: TCurrentLocation = {
        name: location.name,
        url: location.url,
        id: location.id,
      };
      localStorage.setItem('city', JSON.stringify(city));
      setCurrentLocation(city);
    }
  },

  /**
   * Get current city for header from LS
   */
  getCity() {
    const location = localStorage.getItem('city');

    if (location) {
      try {
        const city = JSON.parse(location);
        if (city && typeof city === 'object') {
          return city;
        }
      } catch (e) {
        return false;
      }
    }

    return false;
  },

  cents2dollars(value: number) {
    return value / 100;
  },

  formatMoney(money: string | number) {
    const value = typeof money === 'string' ? parseFloat(money) : money;

    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 4,
    }).format(value);
  },

  getUploadError(type: string, code?: number) {
    let error = `Error uploading ${type}`;

    switch (code) {
      case 400:
        error += ': type not supported or file may be broken';
        break;
      case 413:
        error += ': file exceeds size limit';
        break;
      default:
    }

    return error;
  },

  getBankAccountId() {
    return 1000002;
  },

  ucFirst(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },

  getLimitedCountryList() {
    return [
      {value: '16046', name: 'United States', selected: true},
      {value: '16047', name: 'Canada'},
      {value: '41973', name: 'United Kingdom'},
    ];
  },
};
