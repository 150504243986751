export enum Icons {
  cams_old = 'cams_old',
  user = 'icon_user',
  password = 'icon_password',
  home = 'home',
  footer_myads = 'icon_footer_myads',
  footer_postad = 'icon_footer_postad',
  footer_messages = 'icon_footer_messages',
  footer_myas = 'icon_footer_myas',
  filters = 'icon_filters',
  close = 'icon_close',
  caret_down = 'icon_caret_down',
  lock_question = 'lock-question',
  arrow_right_grey = 'arrow-right-grey',
  bitcoin = 'bitcoin',
  bill = 'bill',
  switch_orientation = 'switch-orientation',
  video_upload = 'icon_video_upload',
  loader = 'icon_loader',
  avatar_edit = 'icon_avatar_edit',
  plus_round = 'icon_plus_round',
  arrow_right = 'arrow-right',
  arrow_left = 'arrow-left',
  payments = 'icon_payments',
  edit_username = 'edit-username',
  envelope = 'envelope',
  icon_gear = 'gear',
  logout = 'icon_logout',
  body_person = 'icon_body_person',
  tos = 'icon_tos',
  privacy_policy = 'icon_privacy_policy',
  messages = 'icon_messages',
  view_ad = 'icon_view_ad',
  gear = 'icon_gear',
  upload = 'icon_upload',
  verified_video = 'icon_verified_video',
  plus = 'icon_plus',
  minus = 'icon_minus',
  location_dropdown = 'icon_location_dropdown',
  send_message = 'icon_send_message',
  verified = 'icon_verified',
  phone = 'icon_phone',
  contacts = 'icon_contacts',
  house = 'icon_house',
  bordered_cross_blue = 'icon_bordered_cross_blue',
  menu = 'icon_menu',
  send_reply = 'icon_send_reply',
  place_location = 'icon_place_location',
  place_upload_photo = 'icon_place_upload_photo',
  place_upload_video = 'icon_place_upload_video',
  grid = 'icon_grid',
  monitor = 'icon_monitor',
  edit_username_bordered = 'icon_edit_username_bordered',
  duplicate = 'icon_duplicate',
  location = 'icon_location',
  trash = 'icon_trash',
  header_back = 'icon_header_back',
  logo = 'icon_logo',
  dropdown = 'icon_dropdown',
  burger = 'icon_burger',
  close_modal = 'icon_close_modal',
  post_ad = 'icon_post_ad',
  fullscreen = 'icon_fullscreen',
  contact_us = 'icon_contact_us',
  install_flash = 'icon_install_flash',
  install_arrow = 'icon_install_arrow',
  install_close = 'icon_install_close',
  samsung_home = 'icon_samsung_home',
  check_round = 'icon_check_round',
  alert_close = 'icon_alert_close',
  photo_upload = 'icon_photo_upload',
  close_modal_white = 'icon_close_modal_white',
  chevron = 'chevron',
  warning = 'icon_warning',

  whatsapp = 'icon_whatsapp_big',
  wechat = 'icon_wechat_big',
  viber = 'icon_viber_big',
  telegram = 'icon_telegram_big',
  terId = 'icon_ter_big',
  line = 'icon_line_big',
  facebook = 'icon_facebook_big',
  twitter = 'icon_twitter_big',
  tumblr = 'icon_tumblr_big',
  youtube = 'icon_youtube_big',
  onlyfans = 'icon_onlyfans_big',
  google = 'icon_google',
  instagram = 'icon_instagram',

  pagination_prev = 'icon_pagination_prev',
  pagination_next = 'icon_pagination_next',
  cross = 'icon_cross',
  bordered_cross = 'icon_bordered_cross',
  copy = 'icon_copy',
  check = 'icon_check',
  list_with_clip = 'icon_list_with_clip',
  search = 'icon_search',
  location_marker = 'icon_location_marker',
  close_disabled = 'icon_close_disabled',
  old_mobile = 'icon_old_mobile',

  support_chat = 'support-chat',
}
