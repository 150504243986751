import {Component} from 'react';

import Icon, {Icons} from '~/components/icons';

export default class InputSearch extends Component {
  getClasses = () => {
    const classes = ['primary-text-input'];

    if (this.props.classes) {
      classes.push(this.props.classes);
    }

    if (this.props.error) {
      classes.push('error');
    }

    return classes.join(' ');
  };

  getInputClasses = () => {
    const classes = ['input-container', 'position-relative'];

    if (this.props.icon_left) {
      classes.push('input-container_icon-left');
    }

    if (this.props.icon_right) {
      classes.push('input-container_icon-right');
    }

    if (this.props.icon_clear) {
      classes.push('input-container_icon-clear');
    }

    return classes.join(' ');
  };

  getButtonClasses = (clear = false) => {
    const classes = ['field-icon', 'field-icon_search'];

    if (this.props.icon_right) {
      classes.push('field-icon_align-right');
    }

    if (this.props.icon_clear && clear) {
      classes.push('field-icon_clear');
    }

    if (this.props.icon_not_interactive) {
      classes.push('field-icon_not-interactive');
    }

    return classes.join(' ');
  };

  render() {
    const data = this.props.data || [];

    return (
      <div className={this.getClasses()}>
        <div className="top-form-fields" />

        <div className={this.getInputClasses()}>
          <input
            type="text"
            id={this.props.id}
            name={this.props.name}
            defaultValue={this.props.value}
            placeholder={this.props.placeholder}
            readOnly={this.props.readonly}
            disabled={this.props.disabled}
            autoComplete="off"
            onClick={this.props.onClick}
            onChange={this.props.onChange}
            onBlur={this.props.onBlur}
            {...data}
          />

          {this.props.icon_clear && (
            <button
              onClick={this.props.onClear}
              type="button"
              className={this.getButtonClasses('clear')}
            >
              <Icon id={Icons.cross} className="icon_bordered_cross" />
            </button>
          )}

          <button type="submit" className={this.getButtonClasses()}>
            <Icon id={Icons.search} />
          </button>
        </div>

        <span className="error-message">{this.props.error}</span>
      </div>
    );
  }
}
