import {useState, useMemo, ChangeEvent} from 'react';
import {Link} from 'react-router-dom';
import {useStore} from 'effector-react';
import clsx from 'clsx';
import {useDebouncedCallback} from 'use-debounce';

import {$translationLanguage, setTranslationLanguage} from '~/store/translationLanguage';
import {setShowGlobalLoader} from '~/store/common';
import InputSearch from '~/components/form/input-search';
import Modal, {hideModal} from '~/components/common/Modal';
import Icon, {Icons} from '~/components/icons';

import {languages} from './constants';
import './styles.scss';

const Translations = () => {
  const [searchText, setSearchText] = useState('');
  const selectedLanguage = useStore($translationLanguage);

  const handleSearch = useDebouncedCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  }, 300);

  const handleClose = () => {
    hideModal('language-modal');
    setSearchText('');
  };

  const handleLangClick = (id: string) => {
    setTranslationLanguage(id);
    setShowGlobalLoader(true);
    hideModal('language-modal');

    setTimeout(() => {
      window.location.reload();
    }, 200);
  };

  const searchResults = useMemo(() => {
    if (searchText.length <= 1) {
      return languages;
    }

    return languages.filter((lang) => lang.name.toLowerCase().startsWith(searchText));
  }, [searchText]);

  const noResults = searchText.length > 1 && searchResults.length <= 0;

  return (
    <Modal id="language-modal" title="Choose language" fullScreen hideHeader>
      <div className="language-search">
        <div className="language-search__head">
          <div className="d-flex flex-nowrap align-items-center">
            <div className="w-100">
              <InputSearch
                classes="transparent-input-bg border-none p-b-0"
                icon_left
                id="language-search-input"
                placeholder="Search language"
                name="language"
                icon_not_interactive
                onChange={handleSearch}
                value={searchText}
              />
            </div>

            <button onClick={handleClose} type="button" className="f-s-base weight-400 p-10">
              <Icon id={Icons.close_modal} />
            </button>
          </div>
        </div>

        <div className="language-search__container">
          {noResults && (
            <p className="information color-tertiary p-t-15">
              Sorry, nothing found for your query. Please try again.
            </p>
          )}

          <ul className={clsx('language-search__results', searchText && 'results')}>
            {searchResults.map((lang) => (
              <li key={lang.id}>
                <Link onClick={() => handleLangClick(lang.id)} to="/#">
                  <span className="m-r-10 notranslate">{lang.flag}</span> {lang.name}
                  {lang.id === selectedLanguage && <Icon id={Icons.check_round} />}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Modal>
  );
};

export default Translations;
