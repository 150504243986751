export const appendSBScript = () => {
  const script = document.querySelector('script[data-id=id-62d19f12ca4a2]');
  if (script) {
    return;
  }

  if (!window.sb) {
    window.sb = {};
  }
  window.sb.cmd = window.sb.cmd || [];
  window.sb.search_domain = 'hot.com';

  const sb_script = document.createElement('script');
  sb_script.async = true;
  sb_script.type = 'text/javascript';
  sb_script.id = '62d19f12ca4a2';
  sb_script.setAttribute('data-id', 'id-62d19f12ca4a2');
  sb_script.src = `${document.location.protocol}//${window.sb.search_domain}/sb/sb-2.js`;

  const node = document.getElementsByTagName('script')[0];
  node?.parentNode?.insertBefore(sb_script, node);
};

export const startSB = () => {
  window.sb.cmd.push(() => {
    window.sb_add('62d19f12ca4a2', {
      /// UTM PARAMS ///
      utm_source: 'adultsearch',
      utm_medium: 'searchbar',
      utm_campaign: '',
      utm_term: 'female',
      utm_content: '',
      /// STYLE CONFIG ///
      type: 'responsive',
      iconColor: '',
      iconSearchColor: '#000000',
      backgroundColor: 'transparent',
      fieldBorderColor: '#E0E0E0',
    });
    window.sb_run();

    document.querySelector('.modal-hot-search-content__loader')?.remove();
  });
};
