import {FC, MouseEvent} from 'react';
import {Link} from 'react-router-dom';

import {saveScrollPositionAndRedirect, restoreScrollPosition} from '../../utils/scrollPosition';
import {LinkStoreScrollProps} from './types';

export {restoreScrollPosition};

const LinkStoreScroll: FC<LinkStoreScrollProps> = ({
  children,
  to,
  className,
  target,
}: LinkStoreScrollProps) => {
  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    saveScrollPositionAndRedirect(to, target);
  };

  return (
    <Link to={to} className={className} onClick={handleClick} target={target}>
      {children}
    </Link>
  );
};

export default LinkStoreScroll;
