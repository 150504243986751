import {Portal} from 'react-portal';
import clsx from 'clsx';
import {useDebounce} from 'use-debounce';
import {useEffect, useState} from 'react';
import {IIdleTimer} from 'react-idle-timer';

import Icon, {Icons} from '~/components/icons';
import {clearPincodeSession, getPincode, startPincodeSession} from '~/services/pincode';

import ButtonBordered from '../../form/button-bordered';
import Pad from './Pad';
import {setPincodeStage} from './store';
import './styles.scss';

function PinCode({idleTimerRef}: {idleTimerRef: React.RefObject<IIdleTimer | null>}) {
  const [showForgot, setShowForgot] = useState(false);
  const [pincodeStatus, setPincodeStatus] = useState('');
  const [pincode, setPincode] = useState('');
  const [debouncedPin] = useDebounce(pincode, 300);

  // clear session on start
  useEffect(() => {
    clearPincodeSession();
  }, []);

  // check if pincode is correct
  useEffect(() => {
    setPincodeStatus('');

    if (debouncedPin.length === 4) {
      const savedPincode = getPincode();
      setPincodeStatus(savedPincode === debouncedPin ? 'success' : 'error');

      // clear pincode after wrong input
      if (savedPincode !== debouncedPin) {
        setTimeout(() => {
          setPincode('');
        }, 1000);
      }
    }
  }, [debouncedPin]);

  // process success pincode
  useEffect(() => {
    if (pincodeStatus === 'success') {
      idleTimerRef.current?.reset();

      startPincodeSession(); // this prevents from asking pincode after reload

      setTimeout(() => {
        setPincodeStage('');
      }, 500);
    }
  }, [idleTimerRef, pincodeStatus]);

  return (
    <>
      <Portal>
        <div className="pin-code">
          <div className="container pin-code__container">
            {showForgot ? (
              <>
                <Icon id={Icons.lock_question} className="lock-question color-secondary" />

                <h1 className="text-center p-y-20 text-uppercase">Forgot your PIN CODE?</h1>

                <div className="m-t-20 text-center">
                  To reset your PIN, please delete the application from your device and add it to
                  Home screen once again
                </div>

                <ButtonBordered
                  handleClick={() => setShowForgot(false)}
                  text="Back to pin code"
                  classes="m-t-20"
                />
              </>
            ) : (
              <>
                <h1 className="text-center text-uppercase">Enter PIN CODE</h1>
                <h2 className="text-center color-label">Enter your 4 digit pin code</h2>

                <div className="pin-code__dots">
                  {[0, 1, 2, 3].map((num) => {
                    return (
                      <div
                        key={num}
                        id={`dot-${num}`}
                        className={clsx('pin-code__dot', {
                          active: num < pincode.length,
                          error: pincodeStatus === 'error',
                          correct: pincodeStatus === 'success',
                        })}
                      />
                    );
                  })}
                </div>

                <div className={`pin-code__result ${pincodeStatus === 'error' ? 'error' : ''}`}>
                  {pincodeStatus === 'error' && 'Invalid PIN Code'}
                  {pincodeStatus === 'success' && 'PIN Code is correct'}
                </div>

                <Pad value={pincode} onChange={setPincode} />

                <div className="p-t-20 text-center">
                  <button
                    type="button"
                    onClick={() => setShowForgot(true)}
                    className="pin-code__footer_forgot"
                  >
                    Forgot Pin Code?
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </Portal>
    </>
  );
}

export default PinCode;
