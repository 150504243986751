import {FC, useState, ImgHTMLAttributes} from 'react';

const STUBS = {
  small: '/images/no-photo-small.svg',
  big: '/images/no-photo-big.svg',
};

const Photo: FC<
  {src: string; stubSize: keyof typeof STUBS} & ImgHTMLAttributes<HTMLImageElement>
> = ({src, stubSize, ...rest}) => {
  const [finalSrc, setFinalSrc] = useState(src);

  const handleError = () => {
    setFinalSrc(STUBS[stubSize]);
  };

  return (
    <img // eslint-disable-line jsx-a11y/alt-text
      src={finalSrc}
      onError={handleError}
      {...rest}
    />
  );
};

export default Photo;
