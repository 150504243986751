import {MouseEvent} from 'react';

import Icon, {Icons} from '~/components/icons';

import './styles.scss';

function Pad({value, onChange}: {value: string; onChange: (value: string) => void}) {
  const clickNumber = (event: MouseEvent<HTMLButtonElement>) => {
    if (typeof event.currentTarget.dataset.digit !== 'string') {
      return;
    }

    const number = event.currentTarget.dataset.digit;

    if (value.length < 4) {
      onChange(value + number);
    }
  };

  const clickClear = () => {
    onChange(value.slice(0, -1));
  };

  return (
    <div className="pin-code__keyboard m-b-20">
      {Array.from({length: 9}, (_, i) => i + 1).map((num) => {
        return (
          <button
            key={num}
            type="button"
            data-digit={num}
            onClick={clickNumber}
            className="pin-code__digit"
          >
            {num}
          </button>
        );
      })}
      <div />
      <button type="button" data-digit="0" onClick={clickNumber} className="pin-code__digit">
        0
      </button>
      <button type="button" onClick={clickClear} className="pin-code__footer_clear" tabIndex={0}>
        <Icon id={Icons.arrow_left} className="icon_pincode_clear" />
      </button>
    </div>
  );
}

export default Pad;
