import {Suspense, useEffect} from 'react';
import {Outlet} from 'react-router-dom';

import {initPwaEvent} from '~/utils/pwa';
import customNavigator from '~/utils/navigator';
import BaseLayout from '~/layout/BaseLayout';

import useAccountErrorProcessor from '../system/useAccountErrorProcessor';
import useImpersonatedAuth from '../system/useImpersonatedAuth';
import useExternalAuth from '../system/useExternalAuth';
import Loader from './components/Loader';

const App = () => {
  useAccountErrorProcessor();
  useImpersonatedAuth();
  useExternalAuth();

  useEffect(() => {
    if (!customNavigator.isStandalone()) {
      initPwaEvent();
    }
  }, []);

  return (
    <BaseLayout>
      <Suspense fallback={<Loader />}>
        <Outlet />
      </Suspense>
    </BaseLayout>
  );
};

export default App;
