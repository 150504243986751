import {FC, useEffect, useMemo, useRef} from 'react';
import {useStore} from 'effector-react';
import {useLocation, Link} from 'react-router-dom';

import Modal, {showModal, hideModal} from '~/components/common/Modal';
import Icon, {Icons} from '~/components/icons';
import {$account} from '~/store/account';
import env from '~/config/appEnv';
import {requestHotPopupShown} from '~/services/account';
import {Page} from '~/utils/getLink';
import {generateHotLink} from '~/utils/hotFunded';

import {MODAL_HOT_CAMPAIGN_ID, HOT_PROTECTED_PAGES, HOT_PROTECTED_PAGES_REGEXP} from './constants';
import './styles.scss';

const ModalHotCampaign: FC = () => {
  const location = useLocation();
  const account = useStore($account);
  const modalShown = useRef(false);

  useEffect(() => {
    if (!env.REACT_APP_HOT_CAMPAIGN || env.REACT_APP_HOT_CAMPAIGN === '0') {
      return;
    }

    if (!account) {
      return;
    }

    // don't show popup when hot funded = true or null or hotPausedSince is not empty
    if (account.hotFunded === true || account.hotFunded === null || account.hotPausedSince) {
      return;
    }

    const isUrlProtected = (HOT_PROTECTED_PAGES as string[]).some((ptrn) =>
      location.pathname.startsWith(ptrn)
    );

    const isUrlProtectedRegexp =
      !isUrlProtected && HOT_PROTECTED_PAGES_REGEXP.some((ptrn) => location.pathname.match(ptrn));

    if (isUrlProtected || isUrlProtectedRegexp) {
      showModal(MODAL_HOT_CAMPAIGN_ID);

      // signal backend that modal was shown
      if (!modalShown.current) {
        modalShown.current = true;

        requestHotPopupShown();
      }
    } else {
      hideModal(MODAL_HOT_CAMPAIGN_ID);
    }
  }, [account, location]);

  const hotLink = useMemo(() => generateHotLink(account), [account]);

  return (
    <Modal id={MODAL_HOT_CAMPAIGN_ID} priority={2000} hideHeader canClose={false}>
      <div className="modal-hot-campaign">
        <Icon id={Icons.logo} className="icon_logo icon_logo--blue modal-hot-campaign__logo" />
        <h1 className="modal-hot-campaign__header">Sign Up on HOT.com</h1>
        {account?.agency || account?.type === 'U' ? (
          <div className="modal-hot-campaign__content">
            AdultSearch has partnered up with HOT.com &mdash; an entertainment search engine that
            can bring you thousands of new clients. In order to keep your Ads active and showing on
            the search page of AdultSearch, please <strong>fund your account</strong> on HOT.com
            with the minimum monthly amount.
          </div>
        ) : (
          <div className="modal-hot-campaign__content">
            AdultSearch has partnered up with HOT.com &mdash; an entertainment search engine that
            can bring you thousands of new clients. In order to keep your Ads active and showing on
            the search page of AdultSearch, please also create a <strong>paid Image Promo</strong>{' '}
            on HOT.com and <strong>fund your account</strong> there with the minimum monthly amount.
          </div>
        )}
        <div className="modal-hot-campaign__content">
          If you do not fund your account on HOT.com within the next 48 hours, your ad will be
          removed from Adultsearch.com
        </div>
        <a className="btn primary-submit-btn" href={hotLink}>
          Proceed to HOT.com
        </a>
        <Link className="btn m-t-20" to={Page.MyAds}>
          Return to My Ads
        </Link>
      </div>
    </Modal>
  );
};

export default ModalHotCampaign;
