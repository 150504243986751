import {lazyWithPreload} from '~/utils/lazyWithPreload';

export * from './faq';

export const ContactUs = lazyWithPreload(
  () => import(/* webpackChunkName: 'ContactUs' */ './ContactUsForm')
);

export const PrivacyPolicy = lazyWithPreload(
  () => import(/* webpackChunkName: 'PrivacyPolicy' */ './PrivacyPolicy')
);

export const TermsOfService = lazyWithPreload(
  () => import(/* webpackChunkName: 'TermsOfService' */ './TermsOfService')
);
