import {ErrorInfo, Component, ReactNode} from 'react';

import ButtonSubmit from '~/components/form/button-submit';
import ButtonBordered from '~/components/form/button-bordered';
import logger from '~/utils/logger';
import OfflineBar from '~/layout/OfflineBar';

import {processRequestError} from './helpers';

import './styles.scss';

export default class ErrorBoundary extends Component<{children: ReactNode}, {error?: Error}> {
  state = {
    error: undefined,
  };

  static getDerivedStateFromError(error: Error) {
    return {error};
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logger.error(error, errorInfo);

    this.setState({error});
  }

  handleGotoHome = () => {
    this.reset();
    window.location.href = '/';
  };

  handleGoBack = () => {
    this.reset();
    window.history.back();
  };

  reset() {
    this.setState({error: undefined});
  }

  render() {
    if (this.state.error) {
      const message = processRequestError(this.state.error);

      return (
        <>
          <OfflineBar />

          <div className="error-boundary">
            <div className="error-boundary__container">
              <h2>{message}</h2>
              {!!window.navigator.onLine && <p>We already working on it!</p>}
              {!window.navigator.onLine && (
                <p className="red">
                  You are offline. Please check your internet connection and try again.
                </p>
              )}
              <ButtonSubmit text="Reload" handleClick={() => window.location.reload()} />
              <ButtonBordered classes="m-t-20" text="Go back" handleClick={this.handleGoBack} />
              <ButtonBordered
                classes="m-t-20"
                text="Go to home page"
                handleClick={this.handleGotoHome}
              />
            </div>
          </div>
        </>
      );
    }

    return this.props.children;
  }
}
