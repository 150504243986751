import {createEffect} from 'effector';

import {navigate} from '~/router';
import {Page} from '~/utils/getLink';
import logger from '~/utils/logger';
import {clearToken, saveToken, getToken, setBodyClass} from '~/services/auth';
import {requestLogin, requestLogout} from '~/services/account';
import {TLoginPayload} from '~/services/types';

import {$account, refreshAccountData, resetAccount} from './account';

export const signInFx = createEffect(async (values: TLoginPayload) => {
  const res = await requestLogin(values);

  if (res.token) {
    saveToken(res.token);

    refreshAccountData();

    const origin = new URLSearchParams(window.location.search).get('origin');

    if (origin) {
      window.location.href = origin;
    } else {
      navigate(Page.Account);
    }
  } else if (res['2fa']) {
    navigate(Page.TwoFactor, {state: res['2fa']});
  }
});

export const logout = createEffect(async () => {
  setBodyClass(false);

  try {
    const currentToken = getToken();

    if (currentToken) {
      await requestLogout();
    }
  } catch (error) {
    logger.error(error);

    if (error?.response?.data?.code !== 400) {
      return error.response.data.message;
    }
  } finally {
    const account = $account.getState();
    resetAccount();
    clearToken();

    if (account?.impersonateLogoutUrl) {
      window.location.href = account.impersonateLogoutUrl;
    }
  }
});
