import axios from 'axios';
import {setupCache} from 'axios-cache-interceptor';

import env from '~/config/appEnv';
import {Page} from '~/utils/getLink';
import {logout} from '~/store/auth';
import {navigate} from '~/router';

import {getToken} from './auth';

const instance = axios.create({
  baseURL: env.REACT_APP_AS_API_URL || '',
  headers: {'X-Requested-With': 'XMLHttpRequest'},
});

// prevent duplicating requests
if (__TEST__ !== true) {
  setupCache(instance, {
    cacheTakeover: false,
    interpretHeader: false,
    ttl: 1000 * 2,
  });
}

const attachInterceptors = () => {
  instance.interceptors.request.use((req) => {
    const token = getToken();

    if (token) {
      req.headers.Authorization = `Bearer ${token}`;
    }

    return req;
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      let message = null;

      if (error.response) {
        switch (error.response.status) {
          case 401:
            if (error.response.config.url === env.REACT_APP_CHAT_API_URL) {
              break;
            }

            logout().then(() => {
              window.location.href = Page.SignIn;
            });

            break;
          case 403:
            message =
              error.response.data.message ||
              'Your access is restricted and does not allow this action.';
            break;
          case 422:
            message = error.response.data.errors;
            break;
          // handle every stopped request and save current url to use it later
          case 444:
            navigate(Page.TwoFactor, {
              state: {redirectUrl: window.location.pathname + window.location.search},
            });
            break;
          case 503:
            message = 'Server error. Contact Technical Support.';
            break;
          default:
            message = null;
            break;
        }
      }

      return Promise.reject(message || error);
    }
  );

  return instance;
};

export default attachInterceptors;
