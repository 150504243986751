import {useState, useEffect} from 'react';
import {useStore} from 'effector-react';

import {$error} from '~/store/account';

const useAccountErrorProcessor = () => {
  const error = useStore($error);
  const [, setError] = useState();

  useEffect(() => {
    if (error) {
      setError(() => {
        throw error;
      });
    }
  }, [error]);
};

export default useAccountErrorProcessor;
