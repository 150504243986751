export const setBodyClass = (auth = true) => {
  if (auth) {
    document.body.classList.add('auth');
    document.body.classList.remove('guest');
  } else {
    document.body.classList.add('guest');
    document.body.classList.remove('auth');
  }
};

export const saveToken = (token: string) => {
  localStorage.setItem('auth', token);
};

export const getToken = () => {
  return localStorage.getItem('auth');
};

export const clearToken = async () => {
  localStorage.removeItem('auth');
};
