import {useCallback} from 'react';

import {showModal, hideModal} from './store';
import {ModalId} from './types';

const useModal = (modalId: ModalId) => {
  const _showModal = useCallback(() => {
    showModal(modalId);
  }, [modalId]);

  const _hideModal = useCallback(() => {
    hideModal(modalId);
  }, [modalId]);

  return [_showModal, _hideModal];
};

export default useModal;
