import {createStore, createEvent, createEffect, merge, restore, sample} from 'effector';

import env from '~/config/appEnv';
import {getToken, setBodyClass} from '~/services/auth';
import {clearPincodeSession} from '~/services/pincode';
import {
  TAccount,
  requestAccount,
  requestSettings,
  requestStatistics,
  TAccountSettings,
  TAccountStatistics,
} from '~/services/account';
import logger from '~/utils/logger';
import {gaSet} from '~/utils/ga';

import {setShowSidebar} from './common';

export const refreshAccountData = createEvent();
export const resetAccount = createEvent();

const loadAccountFx = createEffect(requestAccount);
export const $account = restore<TAccount>(loadAccountFx.doneData, null).reset(resetAccount);

const loadSettingsFx = createEffect(requestSettings);
export const $settings = restore<TAccountSettings>(loadSettingsFx.doneData, null).reset(
  resetAccount
);

const loadStatisticsFx = createEffect(requestStatistics);
export const $statistics = restore<TAccountStatistics>(loadStatisticsFx.doneData, null).reset(
  resetAccount
);

export const loadAccountDataFx = createEffect().use(() => {
  if (!getToken()) {
    return Promise.resolve();
  }

  return Promise.all([loadAccountFx(), loadSettingsFx(), loadStatisticsFx()]);
});

loadAccountDataFx.fail.watch((error) => {
  logger.error(error);
});

const errorEvent = merge([loadAccountFx.fail, loadSettingsFx.fail, loadStatisticsFx.fail]);

export const $error = createStore<Error | null>(null).on(errorEvent, (_, {error}) => error);

// trigger all fetches
sample({
  clock: refreshAccountData,
  target: loadAccountDataFx,
});

// run effects when account is loaded
$account.watch((account) => {
  if (!account) {
    setShowSidebar(false);
  }

  if (account) {
    if (env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      gaSet({
        userId: account.id.toString(),
        username: account.username,
        email: account.email,
      });
    }

    setBodyClass();
  }
});

// clear everything around
resetAccount.watch(() => {
  clearPincodeSession();

  if (env.REACT_APP_GOOGLE_ANALYTICS_ID) {
    gaSet({
      userId: null,
      username: null,
      email: null,
    });
  }
});
