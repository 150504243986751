import {FC, memo, useEffect, useRef} from 'react';
import clsx from 'clsx';
import {useStore} from 'effector-react';
import {useTransition} from 'react-transition-state';
import {disablePageScroll, enablePageScroll} from 'scroll-lock';
import {Portal} from 'react-portal';

import Icon, {Icons} from '~/components/icons';

import {ModalProps} from './types';
import {$modal, hideModal} from './store';
import './styles.scss';

const Modal: FC<ModalProps> = ({
  id,
  title,
  align = 'center',
  fullScreen,
  backButton = false,
  hideHeader = false,
  onClickBackButton,
  onClickCloseButton,
  children,
  footer,
  canClose = true,
  priority = 1100,
}) => {
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const modalStore = useStore($modal);
  const [state, toggle] = useTransition({
    timeout: 100,
    preEnter: true,
    preExit: true,
    mountOnEnter: true,
    unmountOnExit: true,
  });

  useEffect(() => {
    toggle(modalStore.includes(id));
  }, [modalStore, id]);

  // enable scroll lock on modal unmount
  useEffect(() => {
    return () => enablePageScroll();
  }, []);

  useEffect(() => {
    if (!scrollableContainerRef.current) {
      return;
    }

    if (state === 'preEnter') {
      disablePageScroll(scrollableContainerRef.current);
    }

    if (state === 'preExit') {
      enablePageScroll(scrollableContainerRef.current);
    }
  }, [state]);

  const handleClickBackButton = () => {
    onClickBackButton?.();
  };

  const handleClickClose = () => {
    onClickCloseButton?.();

    hideModal(id);
  };

  if (!id) {
    throw new Error('id for modal is not defined');
  }

  if (state === 'unmounted') {
    return null;
  }

  return (
    <Portal>
      <div
        id={id}
        data-testid={id}
        style={{zIndex: priority}}
        className={clsx('modal-ng', `modal-ng--${state}`, `modal-ng--${align}`, {
          'modal-ng--fullscreen': fullScreen,
        })}
      >
        <div
          ref={scrollableContainerRef}
          className={clsx('modal-ng__container', {'modal-ng__container--fullscreen': fullScreen})}
        >
          {!hideHeader && (
            <div className={clsx('modal-ng__header', {'modal-ng__header--fullscreen': fullScreen})}>
              {backButton ? (
                <button onClick={handleClickBackButton} className="color-white" type="button">
                  <Icon id={Icons.header_back} />
                </button>
              ) : (
                <div className="m-l-50" />
              )}

              <h2 className="weight-700 w-100 text-center">{title}</h2>

              {canClose ? (
                <div className="modal-ng__close-button-container">
                  <button onClick={handleClickClose} className="color-secondary" type="button">
                    {fullScreen ? (
                      <Icon id={Icons.close_modal_white} />
                    ) : (
                      <Icon id={Icons.alert_close} />
                    )}
                  </button>
                </div>
              ) : (
                <div className="m-l-50" />
              )}
            </div>
          )}

          <div className="modal-ng__body">{children}</div>

          {footer && <div className="modal-ng__footer">{footer}</div>}
        </div>
      </div>
    </Portal>
  );
};

export default memo(Modal);
