import {createEvent, createStore} from 'effector';

import {PaginationStore} from './types';

export function createPaginationStore<ExtraParams = Record<string, unknown>>(key: string) {
  const resetPagingOptions = createEvent();

  const setPagingOptions = createEvent<Partial<PaginationStore> & ExtraParams>();

  const $pagination = createStore<PaginationStore & {key: string}>({
    key,
    offset: 0,
    limit: 10,
  })
    .on(setPagingOptions, (state, payload) => ({
      ...state,
      ...(typeof payload.offset === 'number' ? {offset: payload.offset} : {}),
      ...(typeof payload.limit === 'number' ? {limit: payload.limit} : {}),
    }))
    .reset(resetPagingOptions);

  return {
    $pagination,
    setPagingOptions,
    resetPagingOptions,
  };
}
