import {useEffect, useState, FC} from 'react';
import {useStore} from 'effector-react';

import {$appUpdate} from '~/store/appUpdate';
import Modal, {showModal} from '~/components/common/Modal';
import ButtonSubmit from '~/components/form/button-submit';

const AppUpdate: FC = () => {
  const appUpdate = useStore($appUpdate);
  const [updateStarted, setUpdateStarted] = useState(false);

  useEffect(() => {
    if (appUpdate) {
      showModal('app-update-modal');
    }
  }, [appUpdate]);

  const doUpdate = async () => {
    const serviceWorkerRegistration = await window.navigator?.serviceWorker?.getRegistration();

    if (!serviceWorkerRegistration) {
      return;
    }

    setUpdateStarted(true);

    if (!serviceWorkerRegistration.waiting) {
      window.location.reload();

      return;
    }

    const registrationWaiting = serviceWorkerRegistration.waiting;
    registrationWaiting.postMessage({type: 'SKIP_WAITING'});
    registrationWaiting.addEventListener('statechange', () => {
      if (registrationWaiting.state === 'activated' || registrationWaiting.state === 'redundant') {
        window.location.reload();
      }
    });
  };

  return (
    <Modal id="app-update-modal" title="New Version Available" canClose={!updateStarted}>
      <div className="m-b-20">
        There is a newer version of Adult Search App available. Please update the app by clicking
        &quot;Update Now&quot; button.
      </div>
      <ButtonSubmit handleClick={doUpdate} text="Update Now" loading={updateStarted} />
    </Modal>
  );
};

export default AppUpdate;
