import {lazyWithPreload} from '~/utils/lazyWithPreload';

export {default as AccountChangePassword} from './AccountChangePassword';
export {default as AdEditThumbnail} from './AdEditThumbnail';
export {default as AdDuplicate} from './AdDuplicate';
export {default as Account} from './Account';
export {default as MyAds} from './MyAds';
export {default as AdChangeLocation} from './AdChangeLocation';
export {default as AccountChangeSettings} from './AccountChangeSettings';
export {default as AccountChatSettings} from './AccountChatSettings';
export {default as AccountChangeUsername} from './AccountChangeUsername';
export {default as ChangeToAgency} from './ChangeToAgency';
export {default as BuyTopups} from './BuyTopups';
export {default as AdVerificationRedirect} from './AdVerificationRedirect';
export {default as UnverifiedInfo} from './UnverifiedInfo';
export * from './AdEdit';

export const BuyUpgrades = lazyWithPreload(
  () => import(/* webpackChunkName: 'BuyUpgrades' */ './BuyUpgrades')
);

export const AccountChangeEmail = lazyWithPreload(
  () => import(/* webpackChunkName: 'AccountChangeEmail' */ './AccountChangeEmail')
);

export const AccountConfirmEmail = lazyWithPreload(
  () => import(/* webpackChunkName: 'AccountConfirmEmail' */ './AccountConfirmEmail')
);

export const AccountPayments = lazyWithPreload(
  () => import(/* webpackChunkName: 'AccountPayments' */ './AccountPayments')
);

export const AccountClose = lazyWithPreload(
  () => import(/* webpackChunkName: 'AccountClose' */ './AccountClose')
);
