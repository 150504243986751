import {FC} from 'react';
import {Link} from 'react-router-dom';

import {BreadcrumbsProps} from './types';
import './styles.scss';

const Breadcrumbs: FC<BreadcrumbsProps> = ({links = []}) => {
  return (
    <div className="primary-breadcrumbs">
      <div className="container">
        {links.length > 0 && (
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb bg-transparent">
              {links.map((link, index) => (
                <li
                  key={link.name}
                  className="breadcrumb-item weight-300 f-s-10 d-flex align-items-center"
                >
                  {link.type === 'country' ||
                  link.type === 'state' ||
                  index + 1 === links.length ? (
                    <div className="link">
                      {link.type === 'country' ? link.name.toUpperCase() : link.name}
                    </div>
                  ) : (
                    <Link className="link" to={link.url}>
                      {link.name}
                    </Link>
                  )}
                </li>
              ))}
            </ol>
          </nav>
        )}
      </div>
    </div>
  );
};

export default Breadcrumbs;
