import {FC, useMemo, Suspense, lazy} from 'react';
import clsx from 'clsx';

import {Icons} from './constants';
import {IconProps} from './types';
import {ReactComponent as LoaderIcon} from './svg/icon_loader.svg';
import {ReactComponent as SearchIcon} from './svg/icon_search.svg';
import {ReactComponent as CamsIcon} from './svg/cams_old.svg';
import {ReactComponent as HomeIcon} from './svg/home.svg';
import {ReactComponent as PostAdIcon} from './svg/icon_footer_postad.svg';
import {ReactComponent as MessagesIcon} from './svg/icon_footer_messages.svg';
import {ReactComponent as MyASIcon} from './svg/icon_footer_myas.svg';
import {ReactComponent as HeaderBackIcon} from './svg/icon_header_back.svg';
import {ReactComponent as BurgerIcon} from './svg/icon_burger.svg';

const preloaded: {[key in Icons]?: any} = {
  [Icons.loader]: LoaderIcon,
  [Icons.search]: SearchIcon,
  [Icons.cams_old]: CamsIcon,
  [Icons.home]: HomeIcon,
  [Icons.footer_postad]: PostAdIcon,
  [Icons.footer_messages]: MessagesIcon,
  [Icons.footer_myads]: MyASIcon,
  [Icons.header_back]: HeaderBackIcon,
  [Icons.burger]: BurgerIcon,
};

const Icon: FC<IconProps> = ({id, className, ...props}) => {
  if (!id) {
    throw new Error(`Cannot find icon with id: ${id}`);
  }

  const PreloadedIconComponent = preloaded[id];

  const LazyIconComponent = useMemo(() => {
    if (PreloadedIconComponent) {
      return () => null;
    }

    return lazy(() =>
      import(
        /* webpackChunkName: "Icons", webpackPreload: true,  webpackInclude: /\.svg$/ */ `./svg/${id}.svg`
      ).then(({ReactComponent}) => ({default: ReactComponent}))
    );
  }, [id, PreloadedIconComponent]);

  if (PreloadedIconComponent) {
    return <PreloadedIconComponent className={clsx(className, id)} {...props} />;
  }

  return (
    <Suspense fallback={<span className={clsx(className, id)} />}>
      <LazyIconComponent className={clsx(className, id)} {...props} />
    </Suspense>
  );
};

export default Icon;
