import {useEffect, useState} from 'react';
import {useStore} from 'effector-react';

import {$account} from '~/store/account';

const useFreshChatObject = () => {
  const [value, setValue] = useState(window.fwcrm);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (window.fwcrm && !value) {
        setValue(window.fwcrm);
      }
    }, 500);

    return () => clearInterval(intervalId);
  }, [value]);

  return value;
};

const FreshChat = () => {
  const account = useStore($account);
  const fwcrm = useFreshChatObject();

  useEffect(() => {
    if (!account || !fwcrm) {
      return;
    }

    try {
      fwcrm.on('user:created', () => {
        window.fcWidget.setExternalId(`AS-U-${account.id}`);
        window.fcWidget.user.setEmail(account.email);
        window.fcWidget.user.setFirstName(`A-${account.id} : ${account.email}`);
        window.fcWidget.user.setProperties({
          profileUrl: `https://adultsearch.com/admin/user/${account.id}/view`,
        });
      });
    } catch (error) {
      console.error(error);
    }
  }, [account, fwcrm]);

  return null;
};

export default FreshChat;
