const CacheNames = {
  BudgetAllowAmounts: 'api-budget-allow-amounts',
  EscortsWithQuantity: 'api-cache-escorts-with-quantity',
  PlacesWithQuantity: 'api-cache-places-with-quantity',
  EscortSponsors: 'api-cache-escort-sponsors',
  ForumsLocation: 'api-cache-forums-location',
  BreadcrumbsCity: 'api-cache-breadcrumbs-city',
  Escorts: 'api-cache-escorts',
  EscortsBreadcrumbs: 'api-cache-escorts-breadcrumbs',
  EscortsTopUpsPrice: 'api-cache-escorts-top-ups-price',
  EscortsTypes: 'api-cache-escorts-types',
  Places: 'api-cache-places',
  PlaceTypes: 'api-cache-place-types',
  Cities: 'api-cache-cities',
  CityByUrl: 'api-cache-city-by-url',
  EscortsIds: 'api-cache-escorts-ids',
  EscortsById: 'api-cache-escort-by-id',
  Countries: 'api-cache-countries',
  EscortFilters: 'api-cache-escorts-filters',
  Options: 'api-cache-options',
  FlashUserExternalUserId: 'flash-user-external-user-id',
  GeoLocation: 'api-cache-geo-location',
  ProfileRelatedRequests: 'api-cache-profile-related-requests',
};

export default CacheNames;
