import {AxiosRequestConfig} from 'axios';

import attachInterceptors from './apiInterceptors';

const axios = attachInterceptors();

// Generic request for API response
export const request = async <T>(
  requestUrl: string,
  params: object = {},
  config: AxiosRequestConfig = {}
) => {
  const res = await axios.get<T>(requestUrl, {
    ...config,
    params,
  });

  return res.data;
};

export const requestPost = async <T = void, P = any>(
  requestUrl: string,
  data?: P,
  config?: AxiosRequestConfig
) => {
  const res = await axios.post<T>(requestUrl, data, config);

  return res.data;
};

export const requestPut = async <T = void>(
  requestUrl: string,
  data: object = {},
  config?: AxiosRequestConfig
) => {
  const res = await axios.put<T>(requestUrl, data, config);

  return res.data;
};

export const requestDelete = async <T = void>(
  requestUrl: string,
  data: object = {},
  config?: AxiosRequestConfig
) => {
  const res = await axios.delete<T>(requestUrl, {
    ...config,
    data: {
      ...data,
    },
  });

  return res.data;
};

export default axios;
