import {FC, useEffect} from 'react';

import Icon, {Icons} from '~/components/icons';

import {LoadingProps} from './types';

const Loading: FC<LoadingProps> = ({label = 'load', children}) => {
  // Add class to body for prevent scroll
  useEffect(() => {
    document.body.classList.add('load');

    return () => {
      document.body.classList.remove('load');
    };
  }, []);

  return (
    <div className="loader d-flex" data-testid="loader">
      {children}
      <span className="loader-progress">{label}</span>
      <Icon className="loader-icon" id={Icons.loader} />
    </div>
  );
};

export default Loading;
