import {FC} from 'react';
import {useStore} from 'effector-react';

import Modal, {hideModal} from '~/components/common/Modal';
import {$account} from '~/store/account';
import ButtonSubmit from '~/components/form/button-submit';
import {isAccountUnverified} from '~/services/account';

export const CHAT_UNAVAILABLE_MODAL = 'chat-unavailable-modal';

const ModalChatUnavailable: FC = () => {
  const account = useStore($account);

  if (!account) {
    return null;
  }

  let message = '';
  if (account.type === 'A') {
    message = 'Sorry, chat is unavailable for Agencies';
  } else if (isAccountUnverified(account)) {
    message = 'Sorry, chat is unavailable for Unverified users';
  } else {
    message = 'Please verify your Ad to have access to chat';
  }

  return (
    <Modal id={CHAT_UNAVAILABLE_MODAL} title="Chat is unavailable">
      {message}
      <ButtonSubmit
        classes="m-t-8"
        text="OK"
        handleClick={() => hideModal(CHAT_UNAVAILABLE_MODAL)}
      />
    </Modal>
  );
};

export default ModalChatUnavailable;
