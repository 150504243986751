import {FC} from 'react';
import clsx from 'clsx';

import Icon from '~/components/icons';

import LinkWrapper from './LinkWrapper';
import {FooterItemProps} from '../types';

const FooterItem: FC<FooterItemProps> = ({
  isActive = false,
  url = '#',
  icon,
  badge = 0,
  children,
  disabled = false,
  className,
  onClick,
}) => (
  <div
    className={clsx('footer__item', className, {
      footer__item_active: isActive,
    })}
    onClick={(event) => {
      if (onClick && !disabled) {
        event.preventDefault();
        onClick(event);
      }
    }}
  >
    <LinkWrapper disabled={disabled} to={url} className="footer__item-link">
      <span className="footer__item-contet">
        <Icon
          id={icon}
          className={clsx('footer__item-icon', disabled && 'footer__item-icon--disabled')}
        />
        {badge > 0 && <span className="footer__badge">{badge}</span>}
      </span>
      <span className={clsx('footer__item-title', disabled && 'footer__item-title--disabled')}>
        {children}
      </span>
    </LinkWrapper>
  </div>
);

export default FooterItem;
