import {FC} from 'react';
import {Link, LinkProps} from 'react-router-dom';

const LinkWrapper: FC<{disabled: boolean} & LinkProps> = ({disabled, to, children, ...props}) => {
  if (disabled) {
    return <span {...props}>{children}</span>;
  }

  return (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
};

export default LinkWrapper;
