import {useCallback, useEffect} from 'react';
import {useDebouncedCallback} from 'use-debounce';

const useMobileHeight = () => {
  const updateVhValue = useCallback(() => {
    const vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  const onResize = useDebouncedCallback(() => {
    updateVhValue();
  }, 50);

  useEffect(() => {
    updateVhValue();

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [updateVhValue, onResize]);
};

export default useMobileHeight;
