import {FC, useEffect} from 'react';
import {useStore} from 'effector-react';
import Skeleton from 'react-loading-skeleton';

import ButtonSubmit from '~/components/form/button-submit';
import {changeTitle} from '~/store/common';
import {$city, fetchCityByUrlFx} from '~/store/cities';
import {precacheData} from '~/store/precache';

import EscortsCounters from './components/EscortsCounters';
import PlacesCounters from './components/PlacesCounters';
import Sponsors from './components/Sponsors';
import Forums from './components/Forums';
import {loadSponsors} from './store';
import './styles.scss';

const City: FC = () => {
  const city = useStore($city);
  const loadingCity = useStore(fetchCityByUrlFx.pending);

  useEffect(() => {
    if (city) {
      loadSponsors();
      precacheData();
      changeTitle(`${city.name}, ${city.countryName}`);
    }
  }, [city]);

  return (
    <>
      <div className="m-b-10 bg-white">
        <div className="container">
          {loadingCity ? (
            <Skeleton height={23} count={1} className="m-t-15" />
          ) : (
            <h2 className="weight-700 p-t-15 color-primary">Erotic Services in {city?.name}</h2>
          )}
          <EscortsCounters />
          <Sponsors />
        </div>
      </div>

      <PlacesCounters />

      <Forums />

      {!!city?.id && (
        <div className="bg-white m-y-10">
          <div className="container">
            <div className="page-actions p-y-15 d-flex flex-row justify-content-between">
              <ButtonSubmit link={`${city.url}/add`} text="Add New Place" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default City;
