import {useState, useEffect, ComponentType} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {DEFAULT_LIMITS} from './constants';
import {getOffsetFromQueryString, getLimitFromStorage} from './helpers';

function withPagination(
  Component: ComponentType<any>,
  {key, limits = DEFAULT_LIMITS}: {key: string; limits?: number[]}
) {
  const WrappedComponent = (props: any) => {
    const location = useLocation();
    const [offset, setOffsetState] = useState(getOffsetFromQueryString(location.search));
    const [limit, setLimitState] = useState(getLimitFromStorage(key, limits));
    const navigate = useNavigate();

    const setOffset = (_offset: number) => {
      if (_offset !== offset) {
        setOffsetState(_offset);

        navigate({search: _offset > 0 ? `?offset=${_offset}` : undefined}, {replace: true});
      }
    };

    const setLimit = (_limit: number) => {
      setLimitState(_limit);

      window.localStorage.setItem(`itemsPerPage-${key}`, String(_limit));
    };

    const resetPage = () => {
      setOffset(0);
    };

    const setOffsetAndLimit = (_offset: number, _limit: number) => {
      setLimit(_limit);
      setOffset(_offset);
    };

    useEffect(() => {
      const parsedOffset = getOffsetFromQueryString(location.search);

      if (parsedOffset !== offset) {
        setOffsetState(parsedOffset);
      }
    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      const parsedLimit = getLimitFromStorage(key, limits);

      if (parsedLimit && limit !== parsedLimit && limits.includes(parsedLimit)) {
        setLimit(parsedLimit);
      }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Component
        {...props}
        resetPage={resetPage}
        offset={offset}
        limit={limit}
        limits={limits}
        setLimit={setLimit}
        setOffset={setOffset}
        setOffsetAndLimit={setOffsetAndLimit}
      />
    );
  };

  return WrappedComponent;
}

export default withPagination;
