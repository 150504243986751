import type {Router, RouterNavigateOptions, To} from '@remix-run/router';

// eslint-disable-next-line import/no-mutable-exports
export let router: Router | null = null;

export const navigate = (to: To, opts?: RouterNavigateOptions) => {
  return router?.navigate(to, opts);
};

export const injectRouter = (_router: Router) => {
  router = _router;
};
