import {restore, createEvent} from 'effector';

export const setUnreadDialogsCount = createEvent<number>();
export const $unreadDialogsCount = restore<number>(setUnreadDialogsCount, 0);

// NOTE: commented out until it works everywhere
// $unreadDialogsCount.watch((count) => {
//   // NOTE: this could not work
//   if (navigator.setAppBadge && count > 0) {
//     navigator.setAppBadge().catch(console.error);
//   }

//   if (navigator.clearAppBadge && count <= 0) {
//     navigator.clearAppBadge().catch(console.error);
//   }
// });
