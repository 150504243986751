import {TAccount} from '~/services/account';

export function generateHotLink(account: TAccount | null) {
  if (!account) {
    return '';
  }

  const queryString = new URLSearchParams({
    src: 'as',
    utm_source: 'as',
    content: account
      ? btoa(
          JSON.stringify({
            uid: account.id,
            u: account.username || '',
            e: account.email || '',
            p: account.escortsVerifiedPhones?.[0] || '',
            v: Number(account.isVerifiedThroughEscorts || false),
            type: Number(account.agency || false),
          })
        )
      : '',
  });

  return `https://hot.com/advertiser-signup?${queryString}`;
}
