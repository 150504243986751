import {memo, useEffect, useMemo, lazy, Suspense} from 'react';
import {useLocation} from 'react-router';

import customNavigator from '~/utils/navigator';
import {getToken, setBodyClass} from '~/services/auth';

import Header from './Header';
import Translations from './Translations';
import Footer from './Footer';
import SidebarMenu from './SidebarMenu';
import ModalOrientationChange from './ModalOrientationChange';
import ModalChatUnavailable from './ModalChatUnavailable';
import ModalHotCampaign, {ModalHotAdsPaused} from './ModalHotCampaign';
import ModalUnfinishedPayment from './ModalUnfinishedPayment';

const ModalHotSearch = lazy(() => import('./ModalHotSearch'));
const InstallPwaModal = lazy(() => import('./InstallPwaModal'));
const InstallPwaBanner = lazy(() => import('./InstallPwaBanner'));

const BaseLayout = memo(({children}) => {
  const location = useLocation();

  const isChat = useMemo(() => location.pathname.includes('/chat/'), [location]);

  useEffect(() => {
    if (getToken()) {
      setBodyClass();
    }

    if (customNavigator.isMobileSafari()) {
      document?.body?.classList?.add('mobile-safari');
    }

    if (customNavigator.isStandalone()) {
      document?.body?.classList?.add('standalone');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isChat && <Header />}
      <Translations />
      <SidebarMenu />
      {children}
      {!isChat && <Footer />}
      <ModalOrientationChange />
      <ModalChatUnavailable />
      <ModalHotCampaign />
      <ModalHotAdsPaused />
      <ModalUnfinishedPayment />

      <Suspense fallback={null}>
        <InstallPwaBanner />
        <InstallPwaModal />
        <ModalHotSearch />
      </Suspense>
    </>
  );
});

BaseLayout.displayName = 'BaseLayout';

export default BaseLayout;
