import axios from 'axios';

import {captureException} from '~/utils/sentry';

export default {
  debug: (...args: any[]) => {
    if (process.env.NODE_ENV !== 'development') {
      return;
    }

    // eslint-disable-next-line no-console
    console.log(...args);
  },

  error: (error: any, ...rest: any) => {
    if (axios.isAxiosError(error)) {
      captureException(error, {
        contexts: {
          requestData: error?.config?.data,
          responseBody: error?.response?.data as any,
          data: rest,
        },
      });
    } else {
      captureException(error, {
        contexts: {
          data: rest,
        },
      });
    }

    // eslint-disable-next-line no-console
    console.error(error);
  },
};
