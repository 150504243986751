import {createEvent, createEffect, restore, sample} from 'effector';

import adbuild from '~/services/adbuild';
import {$account, refreshAccountData} from '~/store/account';
import {TAccount} from '~/services/account';
import business, {UnfinishedBusinessDetails} from '~/utils/business';

export const clearAllUnfinishedDataFx = createEffect(() => {
  adbuild.clearCookie(true);
  business.clearUnfinishedBusinessDetails();
});

const setUnfinishedBusiness = createEvent<UnfinishedBusinessDetails | undefined>();
export const $unfinishedBusiness = restore(setUnfinishedBusiness, null).reset(
  clearAllUnfinishedDataFx
);

const setUnfinishedEscort = createEvent<{escortId: number} | undefined>();
export const $unfinishedEscort = restore(setUnfinishedEscort, null).reset(clearAllUnfinishedDataFx);

const checkUnfinishedFx = createEffect(async (account: TAccount | null) => {
  if (!account) {
    return;
  }

  const unfinishedEscortDetails = adbuild.getUnfinishedEscortDetails();

  if (unfinishedEscortDetails) {
    try {
      await adbuild.linkUnfinishedEscort(account.id, unfinishedEscortDetails.escortId);

      setUnfinishedEscort(unfinishedEscortDetails);
    } catch (error) {
      adbuild.clearCookie(true);
    }
  }

  const unfinishedBusinessDetails = business.getUnfinishedBusinessDetails();
  setUnfinishedBusiness(unfinishedBusinessDetails);
});

// check unfinished stuff after account is loaded
sample({
  clock: $account,
  filter: (account) => !!account,
  target: checkUnfinishedFx,
});

// reload account profiles after linkUnfinishedEscort call
sample({
  clock: setUnfinishedEscort,
  target: refreshAccountData,
});
