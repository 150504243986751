import {lazyWithPreload} from '~/utils/lazyWithPreload';

export const AdEdit = lazyWithPreload(() => import(/* webpackChunkName: 'AdEdit' */ './AdEdit'));

export const AdEditStep1 = lazyWithPreload(() => import(/* webpackChunkName: 'Step1' */ './Step1'));

export const AdEditStep2 = lazyWithPreload(() => import(/* webpackChunkName: 'Step2' */ './Step2'));

export const AdEditConfirm = lazyWithPreload(
  () => import(/* webpackChunkName: 'AdEditConirm' */ './Confirm')
);
