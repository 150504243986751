import {TEscort, ListResponse} from '~/types/common';

export enum EscortIds {
  Female = 1,
  Shemale = 2,
  Male = 3,
  MaleForMale = 4,
  EscortAgency = 5,
  BodyRybs = 6,
  MaleForMaleBodyRubs = 7,
  Strippers = 12,
  BDSMFetishEscorts = 13,
  HelpWantedAds = 14,
  WebcamSites = 15,
  PhoneAndWeb = 16,
}

export enum EscortSlug {
  Female = 'female-escorts',
  Shemale = 'tstv-shemale-escorts',
  BodyRybs = 'body-rubs',
}

export interface EscortType {
  id: EscortIds;
  slug: EscortSlug;
  name: string;
}

export type EscortsTypes = EscortType[];

export interface TopUpsPrice {
  price: number;
  topUps: number[];
}

export type BaseEscortsResponse = ListResponse<TEscort>;

export type EscortTypeWithQuantity = EscortType & {
  url: string;
  counts: {
    escort: number;
    sponsor: number;
  };
};

export type EscortTypeWithQuantityResponse = ListResponse<EscortTypeWithQuantity>;
