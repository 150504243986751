import CacheNames from '~/config/cacheNames';
import {purgeCache} from '~/utils/cache';

import {CityResponseData} from './types';
import axios from './api_init';

export const createCityUrl = (
  countryName: string,
  stateName: string | undefined,
  cityName: string
) => {
  const possibleStateName = stateName ? `${stateName}/` : '';

  return `/${countryName}/${possibleStateName}${cityName}`;
};

export const getCityById = async (id: string | number, refresh = false) => {
  if (refresh) {
    await purgeCache(CacheNames.Cities);
  }

  const res = await axios.get(`/api/v1/cities/${id}`);

  return res.data;
};

export const getCityByUrl = async (
  countryName: string,
  stateName: string | undefined,
  cityName: string
): Promise<CityResponseData> => {
  const res = await axios.get<CityResponseData>(
    `/api/v1/city_by_url${createCityUrl(countryName, stateName, cityName)}`
  );

  return res.data;
};
