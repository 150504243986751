import {Component} from 'react';
import {withIdleTimer, IIdleTimer} from 'react-idle-timer';

class IdleTimer extends Component<IIdleTimer> {
  render() {
    return this.props.children || null;
  }
}

export default withIdleTimer(IdleTimer);
