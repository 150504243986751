import {FC, useEffect} from 'react';
import {useNavigate} from 'react-router';
import clsx from 'clsx';
import {useTransition} from 'react-transition-state';
import {useStore} from 'effector-react';
import {disablePageScroll, enablePageScroll} from 'scroll-lock';

import {Page} from '~/utils/getLink';
import customNavigator from '~/utils/navigator';
import {setShowGlobalLoader, $showSidebar, setShowSidebar} from '~/store/common';
import {$account} from '~/store/account';
import {logout} from '~/store/auth';
import {$myAds} from '~/store/myAds';
import Icon, {Icons} from '~/components/icons';
import {useModal} from '~/components/common/Modal';
import {setPincodeStage} from '~/components/common/PinCodeNG/store';

import SidebarMenuItem from './components/SidebarMenuItem';

import './styles.scss';

const SidebarMenu: FC = () => {
  const navigate = useNavigate();
  const showSidebar = useStore($showSidebar);
  const account = useStore($account);
  const myAds = useStore($myAds);

  const postAdDisabled = Boolean(
    myAds.escorts && account && myAds.escorts.total >= account.maxEscortsNumber
  );

  const [showInstallPwaModal] = useModal('pwa-install-prompt');
  const [state, toggle] = useTransition({
    timeout: 150,
    mountOnEnter: true,
    unmountOnExit: true,
  });

  useEffect(() => {
    toggle(showSidebar);

    if (showSidebar) {
      disablePageScroll();
    } else {
      enablePageScroll();
    }
  }, [showSidebar]);

  const handleSignOutClick = () => {
    setShowSidebar(false);
    setShowGlobalLoader(true);

    logout()
      .then(() => {
        navigate(Page.Home);
      })
      .finally(() => {
        setShowGlobalLoader(false);
      });
  };

  const handleInstallClick = () => {
    setShowSidebar(false);

    showInstallPwaModal();
  };

  const handleItemClick = () => {
    setShowSidebar(false);
  };

  const handleManagePinCodeClick = () => {
    setShowSidebar(false);

    setPincodeStage('setup');
  };

  const isStandalone = customNavigator.isStandalone();

  const rootClasses = clsx('hamburger-menu', `hamburger-menu--${state}`);

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div className={rootClasses} onClick={() => setShowSidebar(false)}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div className="hamburger-menu__wrapper" onClick={(event) => event.stopPropagation()}>
          <div className="hamburger-menu__head">
            <button
              type="button"
              className="btn-link d-flex align-items-center justify-content-center p-x-15 p-t-20 p-b-10"
              onClick={handleItemClick}
            >
              <Icon id={Icons.close_modal} />
            </button>
          </div>

          <div className="hamburger-menu__container">
            <div className="list-with-icons ">
              <ul className="button-list">
                {!account ? (
                  <SidebarMenuItem to={Page.SignIn} onClick={handleItemClick} icon={Icons.logout}>
                    Sign In
                  </SidebarMenuItem>
                ) : (
                  <SidebarMenuItem onClick={handleSignOutClick} icon={Icons.logout}>
                    Logout
                  </SidebarMenuItem>
                )}

                {!account && (
                  <SidebarMenuItem onClick={handleItemClick} to={Page.SignUp} icon={Icons.user}>
                    Sign Up
                  </SidebarMenuItem>
                )}

                {account && (
                  <SidebarMenuItem
                    onClick={handleItemClick}
                    to={Page.Adbuild}
                    icon={Icons.post_ad}
                    disabled={postAdDisabled}
                  >
                    Post Ad
                  </SidebarMenuItem>
                )}

                {account && (
                  <SidebarMenuItem
                    onClick={handleItemClick}
                    to={Page.Recharge}
                    icon={Icons.payments}
                  >
                    Recharge Budget
                  </SidebarMenuItem>
                )}

                <SidebarMenuItem
                  onClick={handleItemClick}
                  to={Page.Privacy}
                  icon={Icons.privacy_policy}
                >
                  Privacy Policy
                </SidebarMenuItem>

                <SidebarMenuItem onClick={handleItemClick} to={Page.Terms} icon={Icons.tos}>
                  Terms Of Service
                </SidebarMenuItem>

                <SidebarMenuItem onClick={handleItemClick} to={Page.Contact} icon={Icons.tos}>
                  Contact Us
                </SidebarMenuItem>

                <SidebarMenuItem onClick={handleItemClick} to={Page.Faq} icon={Icons.tos}>
                  FAQ
                </SidebarMenuItem>

                {account && (
                  <SidebarMenuItem
                    onClick={handleItemClick}
                    to={Page.ChangeSettings}
                    icon={Icons.gear}
                  >
                    Settings
                  </SidebarMenuItem>
                )}

                {!isStandalone && (
                  <SidebarMenuItem onClick={handleInstallClick} icon={Icons.fullscreen}>
                    Add To Home Screen
                  </SidebarMenuItem>
                )}

                {isStandalone && (
                  <SidebarMenuItem onClick={handleManagePinCodeClick} icon={Icons.fullscreen}>
                    Manage Pin Code
                  </SidebarMenuItem>
                )}
              </ul>
            </div>
          </div>

          <div className="hamburger-menu__version">
            <div>Adult Search</div>
            <div>
              {__BUILD_INFO__?.version}-{__BUILD_INFO__?.commit}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SidebarMenu;
