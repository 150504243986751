import {FC, useEffect} from 'react';
import {useStore} from 'effector-react';
import {useNavigate} from 'react-router-dom';

import config from '~/config';
import url from '~/utils/url';
import {$account} from '~/store/account';
import Modal, {showModal, hideModal} from '~/components/common/Modal';
import ButtonSubmit from '~/components/form/button-submit';

import {$unfinishedBusiness, $unfinishedEscort, clearAllUnfinishedDataFx} from './store';

export const UNFINISHED_PAYMENT_MODAL = 'unfinished-payment-modal';

const ModalUnfinishedPayment: FC = () => {
  const account = useStore($account);
  const unfinishedBusiness = useStore($unfinishedBusiness);
  const unfinishedEscort = useStore($unfinishedEscort);
  const navigate = useNavigate();

  const gotoPayment = () => {
    clearAllUnfinishedDataFx();

    if (unfinishedBusiness) {
      navigate(config.getPaymentUrl(unfinishedBusiness));
    }
  };

  const gotoEditAd = () => {
    clearAllUnfinishedDataFx();

    if (unfinishedEscort) {
      navigate(url.getEscortEditLink(unfinishedEscort.escortId));
    }
  };

  useEffect(() => {
    if (unfinishedBusiness || unfinishedEscort) {
      showModal(UNFINISHED_PAYMENT_MODAL);
    } else {
      hideModal(UNFINISHED_PAYMENT_MODAL);
    }
  }, [unfinishedBusiness, unfinishedEscort]);

  if (!account) {
    return null;
  }

  return (
    <Modal id={UNFINISHED_PAYMENT_MODAL} title="Unfinished payment">
      {unfinishedBusiness && (
        <>
          <p className="text-center p-b-20">You have unpaid advertising package waiting.</p>
          <ButtonSubmit handleClick={gotoPayment} text="Continue to payment" />
        </>
      )}

      {unfinishedEscort && (
        <>
          <p className="text-center p-b-20">You have unpaid classified ad waiting.</p>
          <ButtonSubmit handleClick={gotoEditAd} text="Continue to ad" />
        </>
      )}
    </Modal>
  );
};

export default ModalUnfinishedPayment;
