import {FC, memo} from 'react';
import {useStore, useUnit} from 'effector-react';
import Skeleton from 'react-loading-skeleton';

import {EscortTypeWithQuantity} from '~/services/types';
import LinkStoreScroll from '~/components/common/LinkStoreScroll';
import {$escortsTypes, $m4mLinks, loadEscortsTypesFx} from '~/store/profiles';
import {$city, fetchCityByUrlFx} from '~/store/cities';
import '../styles.scss';

const EscortsCounters: FC = () => {
  const escortsTypes = useStore($escortsTypes);
  const city = useStore($city);
  const [loadingTypes, loadingCity] = useUnit([
    loadEscortsTypesFx.pending,
    fetchCityByUrlFx.pending,
  ]);
  const m4mLinks = useStore($m4mLinks);

  const getEscortUrl = (escortType: EscortTypeWithQuantity) => {
    let url = {
      external: false,
      url: '/#',
    };

    if (!escortType || escortType.url === undefined) {
      return url;
    }

    if (escortType.url.toLowerCase().startsWith('http')) {
      url = {
        external: true,
        url: escortType.url,
      };
    } else {
      url = {
        external: false,
        url: `${city?.url}/${escortType.url}`,
      };
    }

    return url;
  };

  if (loadingTypes || loadingCity) {
    return <Skeleton height={30} count={4} className="m-y-15" />;
  }

  return (
    <>
      {escortsTypes.map((escortType) => {
        const url = getEscortUrl(escortType);

        return url.external ? (
          <div key={escortType.id} className="with-counter-btn">
            <a
              href={url.url}
              target="_blank"
              rel="noopener noreferrer"
              className="with-counter-btn_link"
            >
              <span className="text">{escortType.name}</span>
              <span className="count d-flex align-items-center justify-content-center position-relative m-l-20 m-r-30">
                {(escortType.counts.escort || 0) + (escortType.counts.sponsor || 0)}
              </span>
            </a>
          </div>
        ) : (
          <div key={escortType.id} className="with-counter-btn">
            <LinkStoreScroll to={url.url} className="with-counter-btn_link">
              <span className="text">{escortType.name}</span>
              <span className="count d-flex align-items-center justify-content-center position-relative m-l-20 m-r-30">
                {(escortType.counts.escort || 0) + (escortType.counts.sponsor || 0)}
              </span>
            </LinkStoreScroll>
          </div>
        );
      })}

      {m4mLinks.length > 0 && (
        <div className="with-counter-btn">
          <a
            href={m4mLinks[0].url}
            target="_blank"
            rel="noopener noreferrer"
            className="with-counter-btn_link"
          >
            <span className="text">M4M Escorts</span>
            <span className="count count-empty m-r-30" />
          </a>
        </div>
      )}
    </>
  );
};

export default memo(EscortsCounters);
