import {purgeCache} from '~/utils/cache';
import {TPlace} from '~/services/places';
import CacheNames from '~/config/cacheNames';

import axios, {request} from '../api_init';
import {
  PlaceTypesResponse,
  PlacesResponse,
  InformationResponse,
  AttributesResponse,
  ReviewsResponse,
} from './types';

export const MyPlaceTypesUrl = '/api/v1/placeTypes';
export const getPlaceTypes = () => request<PlaceTypesResponse>(MyPlaceTypesUrl);

export const MyPlacesUrl = '/api/v1/pc/places';
export const requestMyPlaces = (limit: number, offset: number) =>
  request<PlacesResponse>(MyPlacesUrl, {
    status: 'active',
    limit,
    offset,
  });

export default {
  getById: async (id: string | number, refresh = false) => {
    if (refresh) {
      await purgeCache(CacheNames.Places);
    }

    const res = await axios.get<TPlace>(`/api/v1/places/${id}`);

    return res.data;
  },

  getInformation: async (id: string | number, refresh = false) => {
    if (refresh) {
      await purgeCache(CacheNames.Places);
    }

    const res = await axios.get<InformationResponse>(`/api/v1/places/${id}/information`);

    return res.data.list;
  },

  getAttributes: async (id: string | number) => {
    const res = await axios.get<AttributesResponse>(`/api/v1/places/${id}/attributes`);

    return res.data.list;
  },

  getReviews: async (id: string | number, refresh = false) => {
    if (refresh) {
      await purgeCache(CacheNames.Places);
    }

    const res = await axios.get<ReviewsResponse>(`/api/v1/places/${id}/reviews`);

    return res.data.list;
  },

  getWorkingHours: async (id: string | number) => {
    const res = await axios.get(`/api/v1/places/${id}/working_hours`);

    return res.data.list;
  },

  getRates: async (id: string | number, refresh = false) => {
    if (refresh) {
      await purgeCache(CacheNames.Places);
    }

    const res = await axios.get(`/api/v1/places/${id}/rates`);

    return res.data.list.filter((item: any) => item.value !== null);
  },

  setTab: (event: any) => {
    const placeId = event.currentTarget.dataset.place_id;
    const tabId = event.currentTarget.id.replace('-tab', '');

    localStorage.setItem(`place-tab-${placeId}`, tabId);
  },

  getTab: (placeId: string | number) => {
    const tab = localStorage.getItem(`place-tab-${placeId}`);

    return tab || 'info';
  },

  getTypes: async (id: string | number) => {
    const res = await axios.get<PlaceTypesResponse>(
      `/api/v1/getPlacesTypesWithQuantityByLocationId?locationId=${id}&offset=0&limit=50`
    );

    return res.data.list;
  },

  getPlacesByLocation: async (locationId: string | number, typeId: number) => {
    const res = await axios.get<PlacesResponse>(
      `/api/v1/places?locationId=${locationId}&typeId=${typeId}&offset=0&limit=1000`
    );

    return res.data.list;
  },
};
