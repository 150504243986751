import {merge} from 'lodash-es';

const env = __ENV__;

if (window?.__CONFIG) {
  merge(env, window.__CONFIG);
}

export default {
  ...env,
};
