import {createStore, createEffect} from 'effector';

import {CityResponseData, BreadcrumbsList} from '~/services/types';
import {Category} from '~/store/types';

export const loadBreadcrumbsFx = createEffect(
  ({city, category}: {city: CityResponseData; category: Category | null}) => {
    return [
      {
        name: 'All',
        url: '/all-locations',
        type: 'home',
      },
      {
        name: city.countryCode.toUpperCase(),
        url: '',
        type: 'country',
      },
      city.stateName
        ? {
            name: city.stateName,
            url: '',
            type: 'state',
          }
        : undefined,
      {
        name: city.name,
        url: city.url,
        type: 'city',
      },
      category
        ? {
            name: 'namePlural' in category ? category.namePlural : category.name,
            url: `${city.url}/${category.url}`,
            type: 'category',
          }
        : undefined,
    ].filter(Boolean) as BreadcrumbsList;
  }
);
export const $breadcrumbs = createStore<BreadcrumbsList>([]).on(
  loadBreadcrumbsFx.doneData,
  (_, payload) => payload
);
