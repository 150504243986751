import {EscortSlug} from '~/services/types';
import {lazyWithPreload} from '~/utils/lazyWithPreload';

const EscortsCategory = lazyWithPreload(
  () => import(/* webpackChunkName: 'Escort' */ './EscortsCategory')
);

export default Object.values(EscortSlug).map((slug) => ({
  path: slug,
  element: <EscortsCategory />,
}));
