import {Component} from 'react';

import {withRouter} from '~/utils/withRouter';
import {Page} from '~/utils/getLink';

import {ScrollToTopProps} from './types';

class ScrollToTop extends Component<ScrollToTopProps> {
  componentDidUpdate(prevProps: ScrollToTopProps) {
    let goTop = true;

    // do not auto scroll when return to my ads
    if (this.props.location.pathname === Page.MyAds) {
      goTop = false;
    }

    if (goTop && this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter<any>(ScrollToTop);
