import {ComponentType} from 'react';
import {useLocation, useNavigate, useParams, Location, NavigateFunction} from 'react-router-dom';

export type WithRouterProps<T, P extends Record<string, string> = Record<string, never>> = T & {
  location: Location;
  navigate: NavigateFunction;
  params: P;
};

export function withRouter<ComponentProps>(Component: ComponentType<ComponentProps>) {
  function ComponentWithRouterProp(props: ComponentProps) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <Component {...props} location={location} navigate={navigate} params={params} />;
  }

  return ComponentWithRouterProp;
}
