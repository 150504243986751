import {MouseEvent} from 'react';
import Cookies from 'universal-cookie';
import range from 'lodash-es/range';

import config from '~/config';
import {navigate} from '~/router';
import {footInchToCm, inchToCm} from '~/utils/measurements';
import axios from '~/services/api_init';
import {TAccount} from '~/services/account';
import {TLanguageOption} from '~/types/common';

import {
  DataFromCookies,
  SummaryItem,
  AdBuildType,
  LocationItems,
  PaymentUrlLocations,
  PaymentsUrlParams,
  UpgradeTypeNames,
  UpgradeKeys,
  Cookie,
  PenisSizeOption,
  HeightOption,
  HipsOption,
  BustOption,
  WaistOption,
  WeightOption,
  ReplyOption,
  UnfinishedEscortDetails,
} from './types';

const UPGRADE_TYPE_NAME: UpgradeTypeNames = {
  escortSponsorLocationIds: 'homesp',
  escortSideLocationIds: 'side_sponsor_',
};

const inchCmOption = (inch: number) => {
  const cm = inchToCm(inch);

  return {
    value: inch,
    name: `${inch}" (${cm}cm)`,
  };
};

export default {
  /**
   * Validate available to
   */
  validateAvailable() {
    let error = true;

    const parent = document.getElementById('available-to');
    const inputs = parent?.getElementsByTagName('input');

    Array.from(inputs ?? []).forEach((input) => {
      if (input.checked || input.dataset.checked) {
        error = false;
      }
    });

    if (error) {
      const parentTop = parent?.getBoundingClientRect().top ?? 0;
      const y = parentTop + window.pageYOffset - 100;

      window.scrollTo({top: y, behavior: 'smooth'});
      return 'You have to pick at least 1 option';
    }
    return false;
  },

  /**
   * Validate incall/outcall
   */
  validateCall() {
    let error = true;

    const incall = document.getElementById('incall') as HTMLInputElement;
    const outcall = document.getElementById('outcall') as HTMLInputElement;

    if (incall.checked || incall?.dataset.checked || outcall.checked || outcall.dataset.checked) {
      error = false;
    }

    if (error) {
      const callRatesTop = document.getElementById('callRates')?.getBoundingClientRect().top ?? 0;

      const y = callRatesTop + window.pageYOffset - 170;
      window.scrollTo({top: y, behavior: 'smooth'});
      return 'You must select either outcall and/or incall';
    }
    return false;
  },

  clearCookie(clearFinish = false) {
    const keys = [
      'adbuildStateId',
      'adbuildPhone',
      'adbuildAccount',
      'adbuildId',
      'adbuildCart',
      'adbuildType',
    ];

    if (clearFinish) {
      keys.push('adbuildFinish');
    }

    this.clearCookieByName(keys);
  },

  clearCookieByName(names: string[]) {
    const cookies = new Cookies();

    names.forEach((key) => {
      cookies.set(key, [], {path: '/', maxAge: 0});
    });
  },

  loadCookie() {
    const cookies = new Cookies();
    const data: DataFromCookies = {
      account: false,
      phone: false,
      escortId: false,
      cartItems: [],
      type: null,
      total: 0,
      stateId: 0,
    };

    // load phone
    const phone = cookies.get<Cookie<string>>('adbuildPhone');
    if (phone !== undefined) {
      data.phone = phone;
    }

    // load accountId
    const account = cookies.get<Cookie<string>>('adbuildAccount');
    if (account !== undefined) {
      data.account = account;
    }

    // load escort id
    const escortId = cookies.get<Cookie<string>>('adbuildId');
    if (escortId !== undefined) {
      data.escortId = escortId;
    }

    // load locations
    const cartItems = cookies.get<Cookie<SummaryItem[]>>('adbuildCart');

    if (Array.isArray(cartItems)) {
      data.cartItems = cartItems;
    }

    // load state
    const stateId = cookies.get<Cookie<string>>('adbuildStateId');
    if (stateId !== undefined) {
      data.stateId = parseInt(stateId, 10);
    }

    // load type id => name
    const type = cookies.get<Cookie<AdBuildType>>('adbuildType');
    if (typeof type === 'object') {
      data.type = type;
    }

    // count total for locations prices
    data.cartItems.forEach((item) => {
      if (item?.price) {
        data.total += parseFloat(item.price);
      }
    });

    return data;
  },

  /**
   * save locations data to cookies
   * @param summary array
   * @param price mixed, if empty - we save only summary
   */
  saveCookieCart(summary: LocationItems, price?: string) {
    const cartItems = Object.values(summary).map((item) => ({
      id: item.id,
      name: item.name,
      state: item.state,
      upgrade: item.upgrade || false,
      price: price ?? item.price,
    }));

    const cookies = new Cookies();

    cookies.set(
      'adbuildCart',
      cartItems,
      {path: '/', expires: new Date(Date.now() + 3600), maxAge: 3600} // 1 hour
    );
  },

  saveCookieStateId(stateId: string) {
    const cookies = new Cookies();

    cookies.set(
      'adbuildStateId',
      stateId,
      {path: '/', expires: new Date(Date.now() + 3600), maxAge: 3600} // 1 hour
    );
  },

  /**
   * Save escort id data for step 4
   * @param escortId
   */
  saveCookieEscortId(escortId: number) {
    const cookies = new Cookies();

    cookies.set(
      'adbuildId',
      escortId,
      {path: '/', expires: new Date(Date.now() + 3600), maxAge: 3600} // 1 hour
    );
  },

  /**
   * Save escort pe id data for step 3
   * @param type
   */
  saveCookieType(type: AdBuildType) {
    const cookies = new Cookies();

    cookies.set(
      'adbuildType',
      type,
      {path: '/', expires: new Date(Date.now() + 3600), maxAge: 3600} // 1 hour
    );
  },

  saveUnfinishedEscortDetails(escortId: number) {
    const cookies = new Cookies();

    cookies.set(
      'adbuildFinish',
      {
        escortId,
      },
      {path: '/', expires: new Date(Date.now() + 3600), maxAge: 30 * 86400} // 1 month
    );
  },

  getUnfinishedEscortDetails(): UnfinishedEscortDetails | undefined {
    const cookies = new Cookies();

    return cookies.get('adbuildFinish');
  },

  linkUnfinishedEscort(accountId: number, escortId: number) {
    return axios.post<void>('/api/v1/link_escort_to_account', {
      escortId,
      accountId,
    });
  },

  saveAccount(accountId: number) {
    const cookies = new Cookies();

    cookies.set(
      'adbuildAccount',
      accountId,
      {path: '/', expires: new Date(Date.now() + 3600), maxAge: 3600} // 1 hour
    );
  },

  /**
   * Create url for payment form
   * @param escortId
   * @param src
   * @returns {string} /payment/pay?paymentType=combo&escortId=1896991&src=escort&locationIds=1279
   * &escortSponsorLocationIds=1279
   * &escortSideLocationIds=1279
   * &escortStickyWeekLocationIds=1279 || &escortStickyMonthLocationIds=1279
   */
  getPaymentUrl(
    escortId: string | boolean = false,
    src: string | boolean = 'escort',
    onlyBc = false
  ) {
    const data = this.loadCookie();

    // escort has upgrades
    let hasUpgrades = false;

    // create ids for locations & upgrades
    const locations: PaymentUrlLocations = {
      locationIds: [],
    };

    data.cartItems.forEach((item) => {
      if (item && typeof item === 'object') {
        if (typeof item.upgrade !== 'string') {
          // base cities
          locations.locationIds.push(item.id);
        } else {
          const upgradeType = item.upgrade;

          if (Object.prototype.hasOwnProperty.call(locations, upgradeType) === false) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            locations[upgradeType] = [];
            hasUpgrades = true;
          }

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          locations[upgradeType]?.push(item.id);
        }
      }
    });

    let locationsUrl = '';
    Object.entries(locations).forEach(([key, value]: any) => {
      locationsUrl += `&${key}=${value.join(',')}`;
    });

    const params: PaymentsUrlParams = {
      paymentType: hasUpgrades ? 'combo' : 'escort',
      escortId: (escortId || data.escortId) as string,
    };

    if (src) {
      params.src = src.toString();
    }

    if (onlyBc) {
      params.only = 'bc';
    }

    const queryUrl = Object.keys(params)
      .map((key) => `${key}=${params[key as keyof PaymentsUrlParams]}`)
      .join('&');

    return `/payment/pay?${queryUrl}${locationsUrl}`;
  },

  /**
   * Check how many locations user can select on step 2
   * @param account Account object
   * @returns {{limit: boolean, error: boolean}}
   */
  getCitiesLimit(account: TAccount) {
    let limit: number | boolean = false;
    let error: string | boolean = false;
    const user = account && Object.keys(account).length > 0 ? account : false;

    if (!user) {
      limit = 2;
      error = `You are allowed to post one ad only in ${limit} location, unless you are proven advertiser. Please log in to your account or contact us at ${config.getEmailSupport()}`;
    } else if (!user.whitelisted && user.type !== 'A') {
      limit = 2;
      error = `You are allowed to post one ad only in ${limit} location, unless you are proven advertiser. If you need to post in more locations, please contact us at ${config.getEmailSupport()}`;
    } else if (user.whitelisted || user.type === 'A') {
      limit = false;
    }

    return {
      limit,
      error,
    };
  },

  getListAge() {
    const list = [];

    for (let i = 21; i < 70; i++) {
      list[i] = i;
    }

    return list;
  },

  async getListEthnicity() {
    const res = await axios.get('/api/v1/options/ethnicity');

    return res.data;
  },

  async getListEyeColor() {
    const res = await axios.get('/api/v1/options/eye_color');

    return res.data;
  },

  async getListKitty() {
    const res = await axios.get(`/api/v1/options/kitty`);

    return res.data;
  },

  getListPenisSize() {
    const list: PenisSizeOption[] = [
      {value: 3, text: `3'`},
      {value: 4, text: `4'`},
      {value: 5, text: `5'`},
      {value: 6, text: `6'`},
      {value: 7, text: `7'`},
      {value: 8, text: `8'`},
    ];

    return list;
  },

  getListReply() {
    const list: ReplyOption[] = [
      {
        value: 1,
        text: 'Yes, make my email anonymous and  forward  inquiries to me.',
        selected: true,
      },
      {value: 3, text: 'Yes, include the email address above.'},
      {value: 2, text: 'No, I don`t want to receive any email inquiries.'},
    ];

    return list;
  },

  async getListCupSize() {
    const res = await axios.get('/api/v1/options/cupsize');

    return res.data;
  },

  async getListHairColor() {
    const res = await axios.get('/api/v1/options/hair_color');

    return res.data;
  },

  async getListBuild() {
    const res = await axios.get('/api/v1/options/build');

    return res.data;
  },

  getListHeightFeet() {
    return [2, 3, 4, 5, 6, 7];
  },

  getListHeightInches() {
    return [
      {value: 411, name: `< 5' (< 152 cm)`},
      {value: 500, name: `5' (${footInchToCm(5, 0)} cm)`},
      {value: 501, name: `5'1" (${footInchToCm(5, 1)} cm)`},
      {value: 502, name: `5'2" (${footInchToCm(5, 2)} cm)`},
      {value: 503, name: `5'3" (${footInchToCm(5, 3)} cm)`},
      {value: 504, name: `5'4" (${footInchToCm(5, 4)} cm)`},
      {value: 505, name: `5'5" (${footInchToCm(5, 5)} cm)`},
      {value: 506, name: `5'6" (${footInchToCm(5, 6)} cm)`},
      {value: 507, name: `5'7" (${footInchToCm(5, 7)} cm)`},
      {value: 508, name: `5'8" (${footInchToCm(5, 8)} cm)`},
      {value: 509, name: `5'9" (${footInchToCm(5, 9)} cm)`},
      {value: 510, name: `5'10" (${footInchToCm(5, 10)} cm)`},
      {value: 511, name: `5'11" (${footInchToCm(5, 11)} cm)`},
      {value: 600, name: `6' (${footInchToCm(6, 0)} cm)`},
      {value: 601, name: `6'1" (${footInchToCm(6, 1)} cm)`},
      {value: 602, name: `6'2" (${footInchToCm(6, 2)} cm)`},
      {value: 603, name: `6'3" (${footInchToCm(6, 3)} cm)`},
      {value: 604, name: `6'4" (${footInchToCm(6, 4)} cm)`},
      {value: 605, name: `6'5" (${footInchToCm(6, 5)} cm)`},
      {value: 606, name: `6'6" (${footInchToCm(6, 6)} cm)`},
      {value: 607, name: `> 6'6" (> ${footInchToCm(6, 6)} cm)`},
    ] as HeightOption[];
  },

  getWeightOptions: () =>
    range(80, 290).map((lbs) => {
      const kg = Math.round(lbs * 0.45359237);

      return {
        value: lbs,
        name: `${lbs} lbs (${kg} kg)`,
      };
    }) as WeightOption[],

  getBustOptions: () => range(28, 49).map(inchCmOption) as BustOption[],
  getWaistOptions: () => range(20, 39).map(inchCmOption) as WaistOption[],
  getHipsOptions: () => range(27, 52).map(inchCmOption) as HipsOption[],

  async getListLanguage() {
    const res = await axios.get<TLanguageOption[]>('/api/v1/options/languages');

    return res.data;
  },

  gotoVerification(escortId: number, source: string) {
    this.saveCookieEscortId(escortId);

    navigate(`/adbuild/confirm${source ? `?src=${source}` : ''}`);
  },

  /**
   * Remove city in summary (by close icon)
   * @param event
   */
  removeSummaryCity(summary: SummaryItem[], event: MouseEvent<HTMLDivElement>) {
    event.preventDefault();

    const id = event.currentTarget.dataset.id;
    const upgrade = event.currentTarget.dataset.upgrade as UpgradeKeys | undefined;

    // check last basic city
    let count = 0;
    summary.forEach((item) => {
      if (item.upgrade === false || !item.upgrade) {
        count++;
      }
    });

    if (!upgrade && count === 1) {
      alert('Cannot remove last city');

      return summary;
    }

    if (upgrade) {
      // delete upgrade
      // eslint-disable-next-line no-param-reassign
      summary = summary.filter(
        (item) => !(item.id === parseInt(id ?? '', 10) && item.upgrade === upgrade)
      );

      // try to uncheck top upgrade list
      // NOTE: this is "trick" is used because the form is uncontrolled
      const checkbox = document.getElementById(
        `${UPGRADE_TYPE_NAME[upgrade]}${id}-control`
      ) as HTMLInputElement | null;

      if (checkbox) {
        checkbox.checked = false;
      }

      // uncheck sticky radio
      if (upgrade.includes('Sticky')) {
        const inputs = document.querySelectorAll(
          `input[name="sticky_sponsor_${id}"]`
        ) as NodeListOf<HTMLInputElement>;

        Array.from(inputs).forEach((item) => {
          // eslint-disable-next-line no-param-reassign
          item.checked = false;
          const parent = item.parentNode as HTMLElement | undefined;

          parent?.classList?.remove('active');
        });
      }
    } else {
      // delete city
      // eslint-disable-next-line no-param-reassign
      summary = summary.filter((item) => item.id !== parseInt(id ?? '', 10));
    }

    return summary;
  },

  async getSubscriptionPrice(accountId: number) {
    const res = await axios.get('/api/v1/escorts/price', {params: {accountId}});

    return res.data;
  },
};
