import {FC, useEffect, useRef} from 'react';

import useLandscapeOrientation from '~/components/system/useLandscapeOrientation';
import Modal, {hideModal, showModal} from '~/components/common/Modal';
import ButtonSubmit from '~/components/form/button-submit';
import Icon, {Icons} from '~/components/icons';

import './styles.scss';

export const MODAL_ORIENTATION_CHANGE = 'modal-orientation-change';

const ModalOrientationChange: FC = () => {
  const isHorizontalOrientation = useLandscapeOrientation();
  const alreadyShown = useRef(false);

  useEffect(() => {
    if (alreadyShown.current) {
      return;
    }

    if (isHorizontalOrientation) {
      showModal(MODAL_ORIENTATION_CHANGE);
      alreadyShown.current = true;
    } else {
      hideModal(MODAL_ORIENTATION_CHANGE);
    }
  }, [isHorizontalOrientation]);

  return (
    <Modal id={MODAL_ORIENTATION_CHANGE} canClose={false} hideHeader>
      <div className="modal-orientation-change">
        <Icon id={Icons.switch_orientation} className="modal-orientation-change__icon" />
        <h1>Switch to portrait mode</h1>
        <p className="modal-orientation-change__title">
          Sorry, for better user experience switch to portrait mode
        </p>
        <ButtonSubmit
          handleClick={() => hideModal(MODAL_ORIENTATION_CHANGE)}
          text="OK"
          classes="modal-orientation-change__button"
        />
      </div>
    </Modal>
  );
};

export default ModalOrientationChange;
