import React, {Component} from 'react';
import {Link} from 'react-router-dom';

export default class ButtonSubmit extends Component {
  getClasses = () => {
    const classes = ['btn', 'primary-submit-btn'];

    if (this.props.classes) {
      classes.push(this.props.classes);
    }

    return classes.join(' ');
  };

  render() {
    const id = this.props.id || '';
    const loading = this.props.loading || false;
    const text = this.props.text || 'Submit';
    const data = this.props.data || [];
    const disabled = this.props.disabled || false;
    const target = this.props.target || '_self';

    return (
      <>
        {this.props.link && (
          <Link
            id={id}
            className={this.getClasses()}
            disabled={!!(loading || disabled)}
            type="text"
            onClick={this.props.handleClick}
            to={this.props.link}
            target={target}
            rel={target === '_blank' ? 'noopener noreferrer' : ''}
            {...data}
          >
            <span>{loading ? 'Please wait ...' : text}</span>
          </Link>
        )}
        {!this.props.link && (
          <button
            id={id}
            className={this.getClasses()}
            disabled={!!(loading || disabled)}
            type={this.props.type || 'submit'} // eslint-disable-line react/button-has-type
            onClick={this.props.handleClick}
            {...data}
          >
            <span>{loading ? 'Please wait ...' : text}</span>
          </button>
        )}
      </>
    );
  }
}
