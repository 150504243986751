import {createStore, createEffect} from 'effector';

import logger from '~/utils/logger';
import {Forum} from '~/services/types';
import {getForums, createTopic} from '~/services/forums';

export const loadForumsFx = createEffect(getForums);

export const createTopicFx = createEffect(createTopic);

export const $forums = createStore<Forum[]>([])
  .on(loadForumsFx.doneData, (_, payload) => payload.list)
  .on(loadForumsFx.fail, () => []);

loadForumsFx.fail.watch(({error}) => {
  logger.error(error);
});

export const $hasForums = $forums.map((forums) => forums.length > 0);
