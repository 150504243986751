import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loading-skeleton/dist/skeleton.css';

import './utils/sentry';
import './styles/app.scss';
import * as serviceWorker from './sw/serviceWorker';
import {setAppUpdateReady} from './store/appUpdate';
import ErrorBoundary from './components/system/Error';
import Bootstrap from './components/App/Bootstrap';

if (process.env.NODE_ENV !== 'test') {
  // eslint-disable-next-line no-console
  console.log('Build info', __BUILD_INFO__);
}

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Bootstrap />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.register({
  onUpdate: () => setAppUpdateReady(true),
  // eslint-disable-next-line no-console
  onSuccess: () => console.log('Service worker is registered'),
});
