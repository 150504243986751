import {createStore, createEvent} from 'effector';

export const changeTitle = createEvent<string>('Change title');
export const $title = createStore<string>('').on(changeTitle, (state, payload) => payload);

export const setVideoPlay = createEvent<boolean>('Set video playing flag');
export const videoPlay = createStore(false).on(setVideoPlay, (state, payload) => payload);

export const setShowGlobalLoader = createEvent<boolean>('Show global loader');
export const $globalLoader = createStore(false).on(setShowGlobalLoader, (_, payload) => payload);

export const setShowSidebar = createEvent<boolean>('Show sidebar');
export const $showSidebar = createStore<boolean>(false).on(setShowSidebar, (_, payload) => payload);
