import logger from '~/utils/logger';
import {purgeCache} from '~/utils/cache';

import CacheNames from '../config/cacheNames';
import axios from './api_init';

export default {
  getPlace: async (id) => {
    const res = await axios.get(
      `/api/v2/getEscortSponsorsByLocationId?locationId=${id}&offset=0&limit=27`
    );

    return res.data.list;
  },

  getEscort: async (cityId, escortType, refresh = false) => {
    let list = [];

    if (refresh) {
      await purgeCache(CacheNames.Cities);
    }

    try {
      const res = await axios.get(`/api/v1/cities/${cityId}/escort_types/${escortType}/sponsors`);

      list = res.data.list;
    } catch (error) {
      logger.error(error);
    }

    return list;
  },
};
