import logger from '~/utils/logger';

import axios from './api_init';
import {Geolocation} from './types';

export const GeolocationUrl = '/api/v1/geo-location';

export const getLocationByIp = async () => {
  try {
    const locaiton = await axios.get<Geolocation>(GeolocationUrl);

    return locaiton.data;
  } catch (error) {
    logger.error(error);

    return null;
  }
};
