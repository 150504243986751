import axios from '~/services/api_init';

import {
  CountryWithoutStates,
  Country,
  CountriesResponse,
  CountryListResponse,
  PopularCitiesResponse,
  CountryNodeListItem,
  StatesTreeNode,
  CityListItem,
  CountryTreeNode,
} from './types';
import {CountriesWithStateUrl} from './constants';

export default {
  async getCountries() {
    const rest = await axios.get<CountryListResponse>('/api/v1/countries?offset=0&limit=500');

    return rest.data.list;
  },

  async getCountriesWithoutState() {
    const res = await axios.get<CountryWithoutStates[]>('/api/v1/countries_without_state');

    return res.data;
  },

  async getCountriesWithState(significantOnly = false) {
    const queryString = significantOnly ? '?significant=1' : '';
    const res = await axios.get<CountriesResponse>(`${CountriesWithStateUrl}${queryString}`);

    return res.data;
  },

  getCountryStates(country: Country) {
    return country.hasStates ? country.states : country.subCountries;
  },

  getCitiesByStateId: async (stateId: number) => {
    // commenting out complete cities list for now
    // const res = await axios.get(`/api/v1/cities?stateId=${stateId}&property=t.name&ordering=asc`);
    const res = await axios.get<PopularCitiesResponse>(`/api/v1/popularCities?stateId=${stateId}`);

    return res.data.list;
  },

  flatStatesList(countries: Country[]) {
    const nodeList: CountryNodeListItem[] = [];

    countries.forEach((item) => {
      if (item.subCountries.length <= 0 && item.states.length <= 0 && item.cities.length <= 0) {
        return;
      }

      nodeList.push({
        value: item.id,
        text: item.name,
        class: 'weight-600 color-black',
        disabled: item.hasStates || item.subCountries.length > 0,
      });

      if (item.hasStates) {
        item.states.forEach(({id, name}) =>
          nodeList.push({
            value: id,
            text: name,
          })
        );
      }

      if (item.subCountries.length) {
        item.subCountries.forEach(({id, name}) =>
          nodeList.push({
            value: id,
            text: name,
          })
        );
      }
    });

    return nodeList;
  },

  statesTree(countries: Country[]) {
    const nodes: StatesTreeNode[] = countries
      .map((country) => {
        const states = country.states.map((state) => {
          const children = state.cities.map((city) => {
            return {
              name: city.name,
              url: city.url,
            };
          });

          return {
            name: state.name,
            children,
          };
        });

        const subCountries = country.subCountries.map((state) => {
          const children = state.cities.map((city) => {
            return {
              name: city.name,
              url: city.url,
            };
          });

          return {
            name: state.name,
            children,
          };
        });

        const cities = country.cities.map((city) => {
          return {
            name: city.name,
            url: city.url,
          };
        });

        return {
          name: country.name,
          children: [
            ...states.filter((state) => state.children.length > 0),
            ...subCountries,
            ...cities,
          ],
        };
      })
      .filter((country) => country.children.length > 0);

    return nodes;
  },
  cityList(countries: Country[]) {
    const cities: CityListItem[] = [];

    countries.forEach((country) => {
      country.states.forEach((state) => {
        state.cities.forEach((city) => {
          cities.push({
            id: city.id,
            name: city.name,
            state: state.name,
            country: country.name,
            isPopular: city.isPopular,
            url: city.url,
          });
        });
      });

      country.subCountries.forEach((state) => {
        state.cities.forEach((city) => {
          cities.push({
            id: city.id,
            name: city.name,
            state: state.name,
            country: country.name,
            isPopular: city.isPopular,
            url: city.url,
          });
        });
      });

      country.cities.forEach((city) => {
        cities.push({
          id: city.id,
          name: city.name,
          country: country.name,
          isPopular: city.isPopular,
          url: city.url,
        });
      });
    });

    return cities;
  },

  grouppedCountries(countries: Country[]) {
    const countriesWithStates: CountryTreeNode[] = [];

    const otherCountries: CountryTreeNode = {
      value: 0,
      text: 'Other Countries',
      children: [],
    };

    countries.forEach((item) => {
      if (item.subCountries.length <= 0 && item.states.length <= 0 && item.cities.length <= 0) {
        return;
      }

      if (item.hasStates || item.subCountries.length) {
        const country: CountryTreeNode = {
          value: item.id,
          text: item.name,
          children: [],
        };

        if (item.hasStates) {
          item.states.forEach(({id, name}) =>
            country.children?.push({
              value: id,
              text: name,
            })
          );
        }

        if (item.subCountries.length) {
          item.subCountries.forEach(({id, name}) =>
            country.children?.push({
              value: id,
              text: name,
            })
          );
        }

        country.children = country.children?.sort((a, b) => a.text.localeCompare(b.text));

        countriesWithStates.push(country);
      } else {
        otherCountries.children?.push({
          value: item.id,
          text: item.name,
        });
      }
    });

    otherCountries.children = otherCountries.children?.sort((a, b) => a.text.localeCompare(b.text));

    countriesWithStates.push(otherCountries);

    return countriesWithStates;
  },
};
