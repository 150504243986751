import {useEffect, useState} from 'react';
import {Portal} from 'react-portal';
import clsx from 'clsx';

import './styles.scss';
import {disablePincodeSetupModal, setPincode, startPincodeSession} from '~/services/pincode';

import Pad from './Pad';
import {setPincodeStage} from './store';

function PinCodeSetup() {
  const [status, setStatus] = useState('enter');
  const [pin, setPin] = useState('');
  const [pinPrev, setPinPrev] = useState('');

  useEffect(() => {
    if (pin.length === 4 && status === 'enter') {
      setPinPrev(pin);
      setTimeout(() => {
        setPin('');
        setStatus('confirm');
      }, 800);
    }

    if (pin.length === 4 && status === 'confirm') {
      if (pin === pinPrev) {
        setStatus('success');

        setTimeout(() => {
          startPincodeSession();
          setPincode(pin);
          setPincodeStage('');
        }, 800);
      } else {
        setStatus('error');
        setTimeout(() => {
          setPin('');
        }, 800);
      }
    }

    if (pin.length < 4 && status === 'error') {
      setStatus('confirm');
    }
  }, [pin, pinPrev, status]);

  const handleSkip = () => {
    disablePincodeSetupModal();
    setPincodeStage('');
  };

  return (
    <Portal>
      <div className="pin-code">
        <div className="container pin-code__container">
          <h1 className="text-center text-uppercase">
            {status === 'enter' ? 'Create new PIN CODE' : 'Confirm PIN CODE'}
          </h1>
          <h2 className="text-center color-label">Enter your 4 digit pin code</h2>

          <div className="pin-code__dots">
            {[0, 1, 2, 3].map((num) => {
              return (
                <div
                  key={num}
                  id={`dot-${num}`}
                  className={clsx('pin-code__dot', {
                    active: num < pin.length,
                    error: status === 'error',
                    correct: status === 'success',
                  })}
                />
              );
            })}
          </div>

          <div className={`pin-code__result ${status === 'error' ? 'error' : ''}`}>
            {status === 'error' && 'PIN code does not match'}
            {status === 'success' && 'PIN code matches'}
          </div>

          <Pad value={pin} onChange={setPin} />

          <div className="p-t-20 text-center">
            <button type="button" onClick={handleSkip} className="pin-code__footer_forgot">
              Skip this step for now
            </button>
          </div>
        </div>
      </div>
    </Portal>
  );
}

export default PinCodeSetup;
