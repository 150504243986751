import {useEffect, useState} from 'react';

const useLandscapeOrientation = () => {
  const [isLandscape, setIsLandscape] = useState(false);
  const setOrientation = () => {
    const isLandscapeOrientation = window.matchMedia(
      '(min-aspect-ratio: 13/9) and (max-height: 690px)'
    ).matches;
    setIsLandscape(isLandscapeOrientation);
  };

  useEffect(() => {
    window.addEventListener('resize', setOrientation);

    setOrientation();

    return () => {
      window.removeEventListener('resize', setOrientation);
    };
  }, []);

  return isLandscape;
};

export default useLandscapeOrientation;
