/* eslint-disable @typescript-eslint/ban-ts-comment */
import {FC, useEffect, useState, memo} from 'react';

import useEscortsRoute from '~/utils/useEscortsRoute';
import {EscortSlug} from '~/services/escorts';

import {BannerProps} from './types';
import './styles.scss';

const Banner: FC<BannerProps> = ({area, width, height}) => {
  const [seed, setSeed] = useState(0);
  const [zoneId, setZoneId] = useState(0);

  const isShemaleRoute = useEscortsRoute(EscortSlug.Shemale);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'test') {
      setSeed(+new Date());
    }

    if (isShemaleRoute) {
      setZoneId(area === 'header' ? 10095 : 10107);
    } else {
      setZoneId(area === 'header' ? 10094 : 10072);
    }
  }, [area, isShemaleRoute]);

  const u = escape(document.URL);
  const sURL = escape(document.referrer);
  const srcUrl = `//adultsearch.com/promo/b?random=${seed}&s=${zoneId}&u=${u}&r=${sURL}`;

  return (
    <div id={`mobile_${area}promo`} className="promo">
      <iframe
        name={`as_ads_frame${seed}`}
        title={`as_ads_frame${seed}`}
        key={+new Date()}
        width={width}
        height={height}
        frameBorder="0"
        marginWidth={0}
        marginHeight={0}
        scrolling="no"
        src={srcUrl}
        loading="lazy"
      />
    </div>
  );
};

export default memo(Banner);
