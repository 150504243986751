import axios, {request, requestPost, requestDelete} from '../api_init';
import {
  ForumsResponse,
  ForumTopicPostsResponse,
  ForumTopicResponse,
  ForumTopicsResponse,
} from './types';

export const getForums = (id: number) =>
  request<ForumsResponse>(`/api/v1/getForumsByLocationId`, {
    locationId: id,
    offset: 0,
    limit: 50,
  });

export const getTopics = (params: {
  cityId: number;
  forumId: number;
  offset: number;
  limit: number;
  query?: string;
}) =>
  request<ForumTopicsResponse>('/api/v1/forumTopics', {
    ...params,
    locationId: params.cityId,
  });

export const createTopic = (params: {text: string; title: string}) =>
  axios.post<{id: number}>('api/v1/pc/forumTopics', params).then((res) => res.data);

export const getTopicById = (id: string) =>
  request<ForumTopicResponse>(`/api/v1/forumTopics/${id}`);

export const getTopicPosts = (params: {
  locationId: string | number;
  topicId: string | number;
  offset: number;
  limit: number;
}) => request<ForumTopicPostsResponse>(`api/v1/forumPosts`, params);

export const getTopicIsSubscribed = (topicId: number) =>
  request(`/api/v1/pc/subscribers?topicId=${topicId}`);

export const subscribeTopic = (topicId: number) => requestPost('/api/v1/pc/subscribers', {topicId});

export const unsubscribeTopic = (topicId: number) =>
  requestDelete('/api/v1/pc/subscribers', {topicId});

export const postTopicReply = ({topicId, text}: {topicId: number; text: string}) =>
  requestPost('/api/v1/pc/forumPosts', {
    topicId,
    text,
  });
