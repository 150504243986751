import Icon, {Icons} from '~/components/icons';

import '../styles.scss';

const Loader = () => {
  return (
    <div data-testid="loader" className="loading-blank-screen">
      <Icon className="loader-icon" id={Icons.loader} />
    </div>
  );
};

export default Loader;
