import Cookies from 'universal-cookie';

export type UnfinishedBusinessDetails = {
  src: string;
  paymentType: string;
  placeId: number;
  recurringPeriod: number;
  ownerPackage: number;
};

export default {
  saveUnfinishedBusinessDetails(cookie: UnfinishedBusinessDetails) {
    const cookies = new Cookies();

    cookies.set(
      'businessFinish',
      cookie,
      {path: '/', expires: new Date(Date.now() + 3600), maxAge: 30 * 86400} // 1 month
    );
  },

  clearUnfinishedBusinessDetails() {
    const cookies = new Cookies();

    cookies.set('businessFinish', {}, {path: '/', maxAge: 0});
  },

  getUnfinishedBusinessDetails(): UnfinishedBusinessDetails | undefined {
    const cookies = new Cookies();

    return cookies.get('businessFinish');
  },
};
