import {createStore, createEffect, createEvent, sample} from 'effector';

import config from '~/config';
import {createCityUrl, getCityByUrl} from '~/services/cities';
import {CityResponseData} from '~/services/types';
import logger from '~/utils/logger';
import {Page} from '~/utils/getLink';
import {navigate} from '~/router';

import {CityFetchProps} from './types';

// for testing purposes
export const setCity = createEvent<CityResponseData | null>('Set city data');

export const fetchCityByUrl = createEvent<CityFetchProps>('Fetch city data');

export const fetchCityByUrlFx = createEffect(
  async ({countryCode, stateName, cityName}: CityFetchProps) => {
    const city = await getCityByUrl(countryCode, stateName, cityName);

    if (!city) {
      navigate(Page.AllLocations);

      return null;
    }

    config.setCity(city);

    return city;
  }
);

fetchCityByUrlFx.fail.watch(({error}) => {
  logger.error(error);
});

export const $city = createStore<CityResponseData | null>(null)
  .on(fetchCityByUrlFx.doneData, (_, payload) => payload)
  .on(setCity, (_, payload) => payload);

export const $error = createStore<boolean>(false)
  .on(fetchCityByUrlFx.fail, () => true)
  .reset(fetchCityByUrl);

// триггер загрузки данных города
// 1. запускаем эвент fetchCityByUrl
// 2. запускается fn, который возвращает данные для target
// 3. функция filter проверяет, что урл города не совпадает с текущим значением из source и fetchCityByUrlFx.pending не true
// 4. запускается fetchCityByUrlFx
sample({
  source: {
    city: $city,
    loading: fetchCityByUrlFx.pending,
  },
  clock: fetchCityByUrl,
  fn: (_, props) => props,
  filter: ({city: currentCity, loading}, {countryCode, stateName, cityName}) => {
    const cityUrl = createCityUrl(countryCode, stateName, cityName);

    return cityUrl !== currentCity?.url && !loading;
  },
  target: fetchCityByUrlFx,
});
