import {createStore, createEvent, createEffect, sample} from 'effector';

import {$city} from '~/store/cities';
import {$account, loadAccountDataFx} from '~/store/account';
import {
  ForumTopic,
  ForumTopicPostsResponse,
  TopicPost,
  getTopicById,
  getTopicPosts,
  getTopicIsSubscribed,
  subscribeTopic,
  unsubscribeTopic,
  postTopicReply,
} from '~/services/forums';

import {PostResult} from './constants';

export const loadTopicById = createEvent<string>();
export const loadTopicPosts = createEvent<{topicId: number; offset: number; limit: number}>();
export const subscribeToggle = createEvent();
export const setIsTopicSubscibed = createEvent<boolean>();
export const resetPage = createEvent();
export const replyToTopic = createEvent<string>();
export const resetReplyResult = createEvent();

export const loadTopicByIdFx = createEffect(getTopicById);
export const loadTopicPostsFx = createEffect(getTopicPosts);
export const getTopicIsSubscribedFx = createEffect(getTopicIsSubscribed);
export const postTopicReplyFx = createEffect(postTopicReply);
const subscribeToTopicFx = createEffect(subscribeTopic);
const unubscribeFromTopicFx = createEffect(unsubscribeTopic);

export const $postTopicReplyResult = createStore<PostResult | null>(null)
  .on(postTopicReplyFx.done, () => PostResult.Success)
  .on(postTopicReplyFx.fail, () => PostResult.Fail)
  .on([resetReplyResult, resetPage], () => null);

export const $topicId = createStore<string | null>(null)
  .on(loadTopicById, (_, topicId) => topicId)
  .reset(resetPage);

export const $topic = createStore<ForumTopic | null>(null)
  .on(loadTopicByIdFx.doneData, (_, payload) => payload.topic)
  .reset(resetPage);

export const $mainPost = createStore<TopicPost | null>(null)
  .on(loadTopicByIdFx.doneData, (_, payload) => payload.post)
  .reset(resetPage);

export const $isSubscribed = createStore<boolean | null>(null)
  .on([getTopicIsSubscribedFx.done, subscribeToTopicFx.done], () => true)
  .on([getTopicIsSubscribedFx.fail, unubscribeFromTopicFx.done], () => false)
  .on(subscribeToggle, () => null)
  .reset(resetPage);

export const $replies = createStore<ForumTopicPostsResponse | null>(null)
  .on(loadTopicPostsFx.doneData, (_, payload) => payload)
  .reset(resetPage);

sample({
  clock: loadTopicById,
  target: loadTopicByIdFx,
});

// check topic subscription status
sample({
  source: {
    account: $account,
    topicId: $topicId,
    loading: getTopicIsSubscribedFx.pending,
  },
  clock: [loadTopicById, loadAccountDataFx.done],
  filter: ({topicId, account, loading}) => !!topicId && !!account && !loading,
  fn: ({topicId}) => Number(topicId),
  target: getTopicIsSubscribedFx,
});

// Run on pagination params to load topic posts
sample({
  source: {
    city: $city,
    loading: loadTopicPostsFx.pending,
  },
  clock: loadTopicPosts,
  filter: ({loading, city}, {topicId}) => !loading && !!city && !!topicId,
  fn: ({city}, {topicId, offset, limit}) => ({
    locationId: city?.id || 0,
    topicId,
    offset,
    limit,
  }),
  target: loadTopicPostsFx,
});

// Make subscribe/unsubscribe request
sample({
  source: {
    topic: $topic,
    isSubscribed: $isSubscribed,
  },
  clock: subscribeToggle,
  filter: ({topic}) => Boolean(topic),
  fn: ({topic, isSubscribed}) => ({topicId: topic?.id, isSubscribed}),
  greedy: true,
}).watch(({topicId, isSubscribed}) => {
  if (!topicId) {
    return;
  }

  if (!isSubscribed) {
    subscribeToTopicFx(topicId);
  } else {
    unubscribeFromTopicFx(topicId);
  }
});

sample({
  source: $topic,
  clock: replyToTopic,
  fn: (topic, text) => ({topicId: topic?.id || 0, text}),
  greedy: true,
  target: postTopicReplyFx,
});

postTopicReplyFx.finally.watch(({params: {topicId}}) => {
  loadTopicByIdFx(topicId.toString());
  setTimeout(() => resetReplyResult(), 3000);
});
